import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Col } from 'reactstrap';
import Swal from 'sweetalert2';
import { config } from '../../config';
import InstallmentCard from './InstallmentCard';
// import KBANKPayNow from './KBANKPayNow';
import iQrBBL from '../../assets/img/logos/logo-QR-BBL.png';

import BBLSlipPage from '../../pages/payment/slip/BBLSlipPage';

class QRBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: '',
      // paymentGateway: 'KBANK',
      paymentGateway: 'ฺbbl',
    };
    this.inputCard = React.createRef();
  }
  componentDidMount() {
    // this.props.payment.changeGateway({ abbr: 'KBANK' });
    this.props.payment.changeGateway({ abbr: 'bbl' });
  }
  componentDidMount() {
    let data = this.props.payment.getData();
    let payment = data.payment;
    // let url = `${config.npay.apiUrl}/charges/kbankqrorder`;
    let url = `${config.npay.apiUrl}/charges/bblqrorder`;
    let method = 'post';
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.props.token}`,
    };

    fetch(url, { method, headers, body: JSON.stringify(payment.uid) })
      .then(response => response.json())
      .then(body => {
        console.log(body);
        // this.setState({ payload: <KBANKPayNow oId={body.data.oId} cType={'qr'} formId="toKbankGatewayQr" displayLogo={false} /> });
        this.setState({ payload: <BBLSlipPage /> });
      });
  }

  changeGateway(card) {
    this.props.payment.changeGateway(card);
    this.setState({ paymentGateway: card.abbr });
  }

  changePayload(load) {
    this.setState({ payload: load });
  }

  async verifyCardBin() {
    console.log('verifyCardBin');
    let payment = this.props.payment.getData();
    let cardNumber = payment.creditcard.number.replace(/[^0-9]/g, '');

    if (cardNumber.length !== 16) {
      Swal.fire({
        type: 'warning',
        title: 'หมายเลขบัตรไม่ถูกต้อง',
      });
      return false;
    }

    let passed = await this.props.payment.chkBinCard(cardNumber);
    if (passed) {
      this.encryptCreditCard(this);
    }
  }

  encryptCreditCard() {
    My2c2p.getEncrypted('2c2p-payment-form', (encryptedData, errCode, errDesc) => {
      if (errCode !== 0) alert(`${errDesc} (${errCode})`);
      else {
        let data = this.props.payment.getData();
        let cardHolderName = data.creditcard.name;

        let payment = {
          uid: data.payment.uid,
          card_holder_name: cardHolderName,
          card_encrypted: encryptedData.encryptedCardInfo,
        };

        let url = `${config.npay.apiUrl}/charges/creditcard`;
        let method = 'post';
        let headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.token}`,
        };

        fetch(url, { method, headers, body: JSON.stringify(payment) })
          .then(response => response.json())
          .then(body => {
            let payload = body.data.payload;
            this.setState({ payload }, () => {
              document.getElementById('ccpp-form').submit();
            });
          });
      }
    });
  }

  render() {
    let data = this.props.payment.getData();
    let payment = data.payment;
    payment.kcardType = 'qr';
    let installment = data.installment;

    // console.log(payment.qrOrder);
    let cards = [{ abbr: 'bbl' }].map(card => {
      return (
        <Col key={card.abbr} md={6} xs={12}>
          <InstallmentCard card={card} selected={installment.card.abbr === card.abbr} onClick={this.changeGateway.bind(this, card)} />
        </Col>
      );
    });

    return (
      <Fragment>
        {/* <CardTitle className="text-center mt-3" style={{ marginBottom: 15 }}>
          กรุณาเลือกธนาคารของท่าน ที่ใช้ในการชำระเงิน
        </CardTitle> */}
        <hr className="custom-hr" />
        <div className="py-2">
          <h6>การชำระด้วย QR / Bill Payment (BBL)</h6>
        </div>
        <div className="d-flex flex-wrap pb-3">
          <img src={iQrBBL} />
        </div>
        <div className="billpayment-container">
          <div>
            <h6>วิธีการชำระเงิน</h6>
            <ol>
              <li>คลิก “พิมพ์ใบชำระเงิน”</li>
              <li>สแกน QR Code หรือ Barcode เพื่อชำระเงินผ่าน Mobile Banking</li>
            </ol>
          </div>
          <br />
          <div>
            <h6>เงื่อนไขการชำระเงิน</h6>
            <ul>
              <li>ชำระผ่านช่องทาง Mobile Banking สามารถชำระได้ โดยจำนวนเงินขึ้นอยู่กับลูกค้ากำหนด limit ของตัวเอง ปกติ 50,000 บาท โดยลูกค้าสามารถขยายวงเงินได้เอง</li>
            </ul>
            <span>** ทางผู้รับชำระเงินอาจเรียกเก็บค่าธรรมเนียมเพิ่มเติมจากราคาดังกล่าว</span>
          </div>
        </div>
        {/* <hr /> */}
        {/* <Row>{cards}</Row> */}
        {/* <hr /> */}
        {/* this.state.paymentGateway === 'bbl' && this.state.payload */}
      </Fragment>
    );
  }
}

export default inject('payment')(observer(QRBox));
