import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import PaymentItem from './PaymentItem';
import iScgcashcard from '../../assets/img/logos/logo-SCGcashcard.png';
import iScgwallet from '../../assets/img/logos/logo-SCGwallet.png';

import InputMask from 'react-text-mask';
const bankChannels = [
  { agentCode: 'scgcashcard', logo: iScgcashcard, bgcolor: 'transparent', name: 'scgcashcard' },
  { agentCode: 'scgwallet', logo: iScgwallet, bgcolor: 'transparent', name: 'SCG wallet' },
];
class ScgWalletBox extends Component {
  changeCardNumber(event) {
    const value = event.target.value.replaceAll(' ', '');
    this.props.scgwallet.changeCardNumber(value);
  }

  render() {
    let scgwallet = this.props.scgwallet.getData();
    let cardMask = [
      /\d/, /\d/, /\d/, /\d/, ' ',
      /\d/, /\d/, /\d/, /\d/, ' ',
      /\d/, /\d/, /\d/, /\d/,
    ];

    let logos = bankChannels.map(channel => {
      return (
        <PaymentItem key={channel.agentCode} type="bank"
          // selected={data.billpayment.agentCode === channel.agentCode}
          logo={channel.logo} bgcolor={channel.bgcolor}
          name={channel.name} agentCode={channel.agentCode}
        // onClick={this.selectPayment.bind(this, channel.agentCode, 'BANKCOUNTER')}
        />
      );
    });

    return (
      <Fragment>
        <hr className="custom-hr" />
        <div className="py-2"><h6>ชำระด้วย SCG Cash Card / SCG Wallet</h6></div>
        <div className="d-flex flex-wrap pb-3">
          <img src={iScgcashcard} height="80px" className="mb-1 mr-2" />
          <img src={iScgwallet} height="80px" className="mb-1 mr-2" />
        </div>
        <FormGroup>
          <Label>Card Number</Label>
          <InputMask keepCharPositions
            id="wallet-number" type="tel"
            className="form-payment"
            mask={cardMask}
            placeholder="กรุณากรอกหมายเลขบัตร"
            value={scgwallet.card_number}
            onChange={this.changeCardNumber.bind(this)}
          />
        </FormGroup>
      </Fragment>
    );
  }
}

export default inject('scgwallet')(observer(ScgWalletBox));
