import React, { Component, Fragment } from 'react';
import iGrabPay from '../../assets/img/icons/grabpay-full-small.png';

class GrabPayPaymentBox extends Component {
  render() {
    return (
      <Fragment>
        <hr className="custom-hr" />

        <div className="py-2">
          <h6>การชำระผ่าน <img src={iGrabPay} height="32px" className="rounded mb-1 mr-2" /></h6>
        </div>
        <div className="billpayment-container">
          <h6>วิธีการชำระเงิน</h6>
          <h6 className="text-red font-weight-bold">กรุณาทำตามขั้นตอนที่แนะนำ (อยู่ระหว่างทำรายการ กรุณาอย่าปิด หรือรีเฟรชหน้าจอ)</h6>
          <ol>
            <Fragment>
              <li>
                <span className="text-red font-weight-bold">กรุณาชำระเงินภายใน 10 นาที เพื่อไม่ให้คำสั่งซื้อของลูกค้า ถูกยกเลิก/หมดอายุ​</span>
              </li>
              <li>คลิก "ยืนยันชำระเงินด้วย GrabPay"</li>
              <li>กรอกเบอร์โทรศัพท์ที่ท่านลงทะเบียนไว้กับ Grab</li>
              <li>กรอก OTP 6 หลัก เพื่อยืนยันการชำระเงิน</li>
              <li>เลือกวิธีการชำระเงิน (Pay in full now) และกดปุ่ม Pay</li>
              <li>กรอก Grab PIN ของท่าน</li>
            </Fragment>
          </ol>
        </div>
      </Fragment>
    );
  }
}

export default GrabPayPaymentBox;
