class Validation {
  username(username) {
    const regEx = /^[a-zA-Z][0-9a-zA-Z]+$/;
    return regEx.test((username || '').toLowerCase());
  }

  password(password) {
    const regEx = /^(?=^.{8,}$)[0-9a-zA-Z!*^?+\-_@#$%&=()~`:;[\]{}'".,/\\]+$/g;
    return regEx.test(password);
  }

  email(email) {
    const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test((email || '').toLowerCase());
  }

  creditCardExpiryDate(expire) {
    if (!expire.match(/(0[1-9]|1[0-2])[/][0-9]{2}/)) {
      return false;
    } else {
      var d = new Date();
      var currentYear = d.getFullYear();
      var currentMonth = d.getMonth() + 1;
      var parts = expire.split('/');
      var year = parseInt(parts[1], 10) + 2000;
      var month = parseInt(parts[0], 10);
      if (year < currentYear || (year == currentYear && month < currentMonth)) {
        return false;
      }
    }
    return true;
  }
}

export const validation = new Validation();
