import React, { Component, Fragment } from 'react';
import moment from 'moment';
import NexterButton from '../Button/NexterButton';

class RequestOTPButton extends Component {
  constructor(props) {
    super(props);
    this.countDownTimerId = 0;
    this.state = {
      remainingTime: 0,
    };
  }

  componentDidMount() {
    this.countDown();
  }

  countDown() {
    this.setState({ remainingTime: this.calculateRemainingTime() });
    clearInterval(this.countDownTimerId);
    this.countDownTimerId = setInterval(() => {
      let remainingTime = this.calculateRemainingTime();
      this.setState({ remainingTime });
      if (remainingTime <= 0) {
        clearInterval(this.countDownTimerId);
      }
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.loading && !this.props.loading) || prevProps.expiryTime !== this.props.expiryTime) {
      this.countDown();
    }
  }

  componentWillUnmount() {
    clearInterval(this.countDownTimerId);
  }

  calculateRemainingTime() {
    let timeNow = new Date().getTime();
    let remainingTime = Math.floor((this.props.expiryTime - timeNow) / 1000);
    return remainingTime;
  }

  formatTime(secs = 0) {
    if (secs >= 60) {
      return `${moment.utc(secs * 1000).format('m:ss')}s`;
    } else {
      return `${secs}s`
    }
  }


  render() {
    let { loading } = this.props;
    let { remainingTime } = this.state;
    return (
      <Fragment>
        <NexterButton color="outline-info" size="sm" className="px-2 text-nowrap" disabled={remainingTime > 0 || loading} onClick={this.props.onClick.bind(this)}>
          {loading ? 'กรุณารอสักครู่' : <Fragment>Request OTP {remainingTime > 0 && `(${this.formatTime(remainingTime)})`}</Fragment>}
        </NexterButton>
      </Fragment>
    );
  }
}
export default RequestOTPButton;
