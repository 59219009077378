
import BaseStore from './BaseStore';
import Swal from 'sweetalert2';
import { config } from '../config';
import { http } from '../utils/http';
import { validation } from '../utils/validation';
import crypto from '../utils/crypto';
import Timer from 'tiny-timer';
import moment from 'moment';
export class UserStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      loading: false,
      user: {
        name: '',
        username: '',
        password: '',
        email: '',
        refid: '',
        reftime: '',
        new_password: '',
        confirm_new_password: '',
        reset_password: false,
        reset_timmer: '',
        type: null,
        channel_uid: null,
      },
      input_type: 'password',
      type_list: [],
      show_channel_options: false,
      validation: {
        type: true,
        channel: true,
        username: true,
        password: true,
        name: true,
        email: true,
      },
      loggedinUser: {},
      selectedChannel: null,
    });
  }

  changePassword(password) {
    this.user.password = password;
  }

  changeNewPassword(password) {
    this.user.new_password = password;
  }

  changeConfirmNewPassword(password) {
    this.user.confirm_new_password = password;
  }

  changeResetPasswordStatus() {
    this.user.reset_password = this.user.reset_password === false ? this.user.reset_password = true : this.user.reset_password = false;
  }

  validatePassword() {
    const regEx = /^(?=^.{8,}$)[0-9a-zA-Z!*^?+\-_@#$%&=()~`:;[\]{}'".,/\\]+$/g;
    let passed = regEx.test(this.user.new_password);

    if (!passed) {
      Swal.fire({
        title: 'Warning!',
        html:
          '<div class="w-75 mx-auto mt-2">' +
          '<p><strong>Password does not match criteria:</strong></p>' +
          '<ul class="text-left">' +
          '<li>Eight (8) characters or more.</li>' +
          '<li>Accepts alphanumeric charecters</li>' +
          '<li>Accepts special charecters</li>' +
          '</ul>' +
          '</div>',
        type: 'warning',
        confirmButtonText: 'OK',
      });

      return false;
    } else if (this.user.new_password !== this.user.confirm_new_password) {
      Swal.fire({
        title: 'Warning!',
        text: 'Password don\'t match',
        type: 'warning',
        confirmButtonText: 'OK',
      });

      return false;
    } else {
      return true;
    }
  }

  toggleShowHide() {
    this.input_type = this.input_type === 'text' ? 'password' : 'text';
  }

  changeLinkTimmer(time) {
    this.user.reset_timmer = time;
  }

  logout() {
    window.location.href = `${config.npay.webUrl}/admin`;
  }

  msToTime(s) {
    var pad = (n, z = 2) => ('00' + n).slice(-z);
    return pad(s / 3.6e6 | 0) + ':' + pad((s % 3.6e6) / 6e4 | 0) + ':' + pad((s % 6e4) / 1000 | 0);
  }

  async validatePassword2() {
    if (!validation.password(this.user.password)) {
      this.validation.password = false;
    } else {
      this.validation.password = true;
    }
  }

  async getUserByRefId(refid) {
    try {
      let url = `${config.npay.apiUrl}/users/ref/${refid}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.user.username = body.data.username;
        this.user.refid = refid;
        this.user.reftime = body.data.ref_expired;
        await this.linkLifeTimer();
        Swal.fire({
          title: 'เริ่มการ Reset Password',
          text: ' Username: ' + body.data.username + ' ',
          type: 'success',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          setTimeout(() => {
          }, 500);
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Access denied',
          type: 'error',
          confirmButtonText: 'OK',
        });
        this.logout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updatePassword() {
    let valid = this.validatePassword();
    if (valid) {
      this.loading = true;
      let data = this.getData();
      let newPassword = crypto.getSha512(data.user.username, data.user.new_password);

      try {
        let url = `${config.npay.apiUrl}/users/password/ref`;
        let updateData = { username: data.user.username, newPassword, refid: this.user.refid };
        let response = await http.put(url, { body: updateData });

        if (response.status === 200) {
          Swal.fire({
            title: 'Success!',
            text: 'Your password is updated',
            type: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            setTimeout(() => {
              this.logout();
            }, 1000);
          });
        } else {
          let body = await response.json();
          Swal.fire({
            title: 'Error!',
            text: body.message,
            type: 'error',
            confirmButtonText: 'OK',
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    }
  }

  async linkLifeTimer() {
    const timenow = new Date();
    const timeend = new Date(this.user.reftime);
    let startTime = moment(timenow, 'YYYY-MM-DD HH:mm:ss');
    let endTime = moment(timeend, 'YYYY-MM-DD HH:mm:ss');
    let duration = moment.duration(endTime.diff(startTime));
    let mils = duration.asMilliseconds();
    const timer = new Timer();
    timer.on('tick', (ms) => this.changeLinkTimmer(this.msToTime(ms)));
    timer.on('done', () => this.logout());
    timer.start(mils);
  }
}
