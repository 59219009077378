import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import iBBLFull from '../../../assets/img/logos/logo-BBL-full.png';
// import sampleLogo from '../../../assets/img/samples/image-80px.png';
import sFinPay from '../../../assets/img/sfinpay-logo.png';
// import sampleQrcode from '../../../assets/img/samples/sample-qrcode.png';
// import iThaiQrPayment from '../../../assets/img/logos/logo-thai-qr-payment.png';
import '../../../assets/scss/pages/bblslippage.scss';
import Barcode from 'react-barcode';
// import QRCode from 'react-qr-code';
import QRCode from 'qrcode.react';
import NexterButton from '../../../components/Button/NexterButton';
import iBBL from '../../../assets/img/logos/logo-BBL.png';
import iKaitnakin from '../../../assets/img/logos/logo-KIATNAKIN.png';
import iTBANK from '../../../assets/img/logos/logo-TBANK.png';
import moment from 'moment';

class BBLSlipPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      logo: sFinPay,
      barcode: '',
      barcodeChar: '',
      qrcode: '',
      taxSuffix: '',
      sellerName: '',
      channelName: '',
      channelCallback: '',
      customerName: '',
      ref1: '',
      ref2: '',
      expireDate: '',
      sum: 0,
      displaySum: '',
      activeBBL: true,
      serviceCode: 'XXXXX',
    };
  }

  async componentDidMount() {
    let uid = this.props.match.params.uid;
    await this.props.payment.fetchPayment(uid);
    this.setState({ ...this.state, loading: false });
    const data = this.props.payment.getData();
    // console.log(data);

    let activeBBL = false;
    let payment = data.payment;
    let logo = payment?.channel?.logo_url || sFinPay;
    let channelName = payment?.channel?.name || 'Payment Provider';
    let channelCallback = payment?.channel?.callback_path || '';
    if (channelCallback === 'null') channelCallback = '';
    // console.log('payment channel id', payment.channel.id, 'seller channel id', payment.seller.channel_id, 'seller type', payment.seller.type);
    activeBBL = payment.channel && payment.seller && payment.channel.id === payment.seller.channel_id && payment.seller.type === 'channel' ? true : false;

    if (!payment.status || payment.status === 'created') {
      activeBBL = false;
    }

    // let expireDate = new Date(payment.expire_at).toLocaleDateString('th-TH');
    let expireDate = moment(payment.expire_at).format('DD/MM/YYYY hh:mm:ss');
    let taxSuffix = payment.seller.tax_suffix;
    if (!taxSuffix || taxSuffix.length !== 15) {
      console.log(taxSuffix.length, typeof taxSuffix);
      console.error('Tax Id + Suffix format is not match character length');
      activeBBL = false;
    }

    let serviceCode = 'XXXXXXX';
    if (taxSuffix === '010554107658699') {
      serviceCode = 'SCGHOME';
    } else if (taxSuffix === '010552702560199') {
      serviceCode = 'NEXTDIGI';
    }

    let sellerName = payment.seller.name;
    let customerName = payment.customer_name;

    // let ref1 = payment.ref_id;
    // ref1 use payment uid by remove 'PAY'

    let ref1 = uid.replace('PAY', '');
    let ref2 = payment.phone;
    let total = payment.items.reduce((total, item) => total + +item.subtotal, 0);
    let discount = payment.items.reduce((discount, item) => discount + +item.discount, 0);
    let sum = +total - +discount || 0;
    let tempSum = sum.toFixed(2);
    let tempArray = tempSum.split('.');
    let displaySum = parseInt(tempArray[0]).toLocaleString('th-TH') + '.' + tempArray[1];

    let barcodeRef1 = this.zeroLeading(ref1, 18);
    if (barcodeRef1 < 0) {
      console.error('Can not format barcodeRef1 to barcode character length');
      activeBBL = false;
    }

    let barcodeRef2 = this.zeroLeading(ref2, 18);
    if (barcodeRef2 < 0) {
      console.error('Can not format barcodeRef2 to barcode character length');
      activeBBL = false;
    }

    let qrcodeRef1 = this.zeroLeading(ref1, 20);
    if (qrcodeRef1 < 0) {
      console.error('Can not format qrcodeRef1 to qrcode character length');
      activeBBL = false;
    }

    let qrcodeRef2 = this.zeroLeading(ref2, 20);
    if (qrcodeRef2 < 0) {
      console.error('Can not format qrcodeRef2 to qrcode character length');
      activeBBL = false;
    }

    let amount = '' + sum.toFixed(2);
    amount = amount.replace('.', '');
    amount = this.zeroLeading(amount, 10);
    if (amount < 0) {
      console.error('Can not format Amount to qrcode/barcode character length');
      activeBBL = false;
    }

    let barcode = `|${taxSuffix}\r${barcodeRef1}\r${barcodeRef2}\r${amount}`;
    let barcodeChar = `|${taxSuffix} ${barcodeRef1} ${barcodeRef2} ${amount}`;
    let qrcode = `|${taxSuffix}\r${qrcodeRef1}\r${qrcodeRef2}\r${amount}`;

    this.setState({
      logo,
      taxSuffix,
      sellerName,
      channelName,
      channelCallback,
      customerName,
      ref1,
      ref2,
      expireDate,
      sum,
      displaySum,
      barcode,
      barcodeChar,
      qrcode,
      activeBBL,
      serviceCode,
    });
  }

  charLeading(input, charLead, charLen) {
    if (input.length < charLen) {
      return String(input).padStart(charLen, charLead);
    } else if (input.length === charLen) {
      return input;
    } else {
      console.error('Input length is greater than character length.');
      return -1;
    }
  }

  zeroLeading(input, charLen) {
    input = '' + input;
    return this.charLeading(input, '0', charLen);
  }

  onPrint(e) {
    e.preventDefault();
    window.print();
  }

  onBack(e) {
    e.preventDefault();
    console.log('Redirect');
  }

  render() {
    return (
      <div style={{ backgroundColor: 'white', minHeight: '100vh', height: '100%', width: '100%' }}>
        <Container className="bbl-slip-container" style={{ width: 794 }}>
          <Row>
            <Col xs={12} className="text-center px-0">
              <Row>
                <div className="d-flex justify-content-center w-100">
                  <div className="logo">
                    <img src={iBBLFull} />
                  </div>
                  <div className="logo with-left-hr">
                    <img src={this.state.logo} alt="logo" style={{ height: 50 }} />
                  </div>
                </div>
              </Row>
              {/* BBL Account Binding SCG HOME or NEXTER DIGITAL */}
              <div className="merchant-name ">Merchant: {this.state.channelName}</div>
              {this.state.activeBBL ? (
                <>
                  <Row className="justify-content-center d-print-none" style={{ margin: '0 auto' }}>
                    <Col xs={12} md={3} className="mb-3 mb-md-0 pr-0 d-flex justify-content-center">
                      <div className="qrcode-container">
                        <span className="slip-title">คิวอาร์โค้ด</span>
                        <div className="qrcode-wrapper bg-white">
                          {this.state.qrcode ? <QRCode value={this.state.qrcode} size={152} level={'M'} renderAs={'svg'} /> : <div style={{ width: 178, height: 178 }} />}
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={9} className="px-0">
                      <Row>
                        <Col md={5} className="text-center px-0">
                          <div>
                            <span className="slip-title">
                              จำนวนเงิน (THB) <i className="far fa-question-circle" style={{ color: 'lightgray', fontSize: '1.125rem' }}></i>
                            </span>
                          </div>
                          <h6 className="price text-center">{this.state.displaySum}</h6>
                          <span className="remark">*กรุณาชำระเงินก่อน</span>
                          <br />
                          <span className="remark">{this.state.expireDate}</span>
                        </Col>
                        <Col md={7} className="with-left-hr text-center px-0">
                          <div>
                            <span className="slip-title slip-title-ref">
                              หมายเลขการชำระเงิน (Ref.1) <i className="far fa-question-circle" style={{ color: 'lightgray', fontSize: '1.125rem' }}></i>
                            </span>
                          </div>
                          <h6 className="price text-center">{this.state.ref1}</h6>
                          <span className="remark">
                            หมายเลขโทรศัพท์ (Ref.2) <i className="far fa-question-circle" style={{ color: 'lightgray', fontSize: '1.125rem' }}></i> {this.state.ref2}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="justify-content-center d-none d-print-flex" style={{ width: '86%', margin: '0 auto' }}>
                    <Col md={3} className="pr-0">
                      <div className="qrcode-container">
                        <span className="slip-title">คิวอาร์โค้ด</span>
                        <div className="qrcode-wrapper bg-white">
                          {this.state.qrcode ? <QRCode value={this.state.qrcode} size={152} level={'M'} renderAs={'svg'} /> : <div style={{ width: 178, height: 178 }} />}
                        </div>
                      </div>
                    </Col>
                    <Col md={9}>
                      <Row>
                        <Col md={5} className="text-center px-0">
                          <div>
                            <span className="slip-title">
                              จำนวนเงิน (THB) <i className="far fa-question-circle" style={{ color: 'lightgray', fontSize: '1.125rem' }}></i>
                            </span>
                          </div>
                          <h6 className="price text-center">{this.state.displaySum}</h6>
                          <span className="remark">*กรุณาชำระเงินก่อน</span>
                          <br />
                          <span className="remark">{this.state.expireDate}</span>
                        </Col>
                        <Col md={7} className="with-left-hr text-center px-0">
                          <div>
                            <span className="slip-title slip-title-ref">
                              หมายเลขการชำระเงิน (Ref.1) <i className="far fa-question-circle" style={{ color: 'lightgray', fontSize: '1.125rem' }}></i>
                            </span>
                          </div>
                          <h6 className="price text-center">{this.state.ref1}</h6>
                          <span className="remark">
                            หมายเลขโทรศัพท์ (Ref.2) <i className="far fa-question-circle" style={{ color: 'lightgray', fontSize: '1.125rem' }}></i> {this.state.ref2}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div style={{ height: '14px' }}></div>
                  <Row className="justify-content-center" style={{ width: '80%', margin: '0 auto' }}>
                    <Col className="barcode-container">
                      {this.state.barcode ? (
                        <div className="position-relative">
                          <Barcode displayValue={false} value={this.state.barcode} background="transparent" format="CODE128" height={64} width={2} fontSize={16} font="Inter" />
                          <div className="position-absolute" style={{ fontSize: '0.75rem', width: '100%', bottom: '-2px' }}>
                            {this.state.barcodeChar}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                <div>
                  รายการนี้ไม่สามารถชำระเงินผ่านช่องทางการชำระเงินนี้ได้
                  <br /> ท่านสามารถเลือกช่องทางอื่นเพื่อทำการชำระเงิน ขอบคุณค่ะ
                </div>
              )}
            </Col>
          </Row>
          {this.state.activeBBL ? (
            <Row className="action-container d-print-none">
              <Col xs={12} className="text-center">
                {this.state.channelCallback && this.state.channelCallback.length > 0 ? (
                  <NexterButton color="secondary" className="custom-nexter-pay-btn px-1" onClick={this.onBack.bind(this)}>
                    Return to Merchant
                  </NexterButton>
                ) : (
                  <NexterButton color="secondary" className="custom-nexter-pay-btn px-1" disabled={true}>
                    Return to Merchant
                  </NexterButton>
                )}
                <NexterButton color="primary" className="custom-nexter-pay-btn" onClick={this.onPrint.bind(this)}>
                  Print
                </NexterButton>
              </Col>
            </Row>
          ) : (
            ''
          )}
          <Row className="information-container d-none d-print-flex">
            <Col xs={12}>
              <Row>
                <Col md={5}>
                  <Row>
                    <Col>
                      <b>Name</b>
                    </Col>
                    <Col>{this.state.customerName}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Contact No.</b>
                    </Col>
                    <Col>{this.state.ref2}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Invoice No.</b>
                    </Col>
                    <Col>{this.state.ref1}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <small>
                        {this.state.serviceCode === 'SCGHOME'
                          ? this.state.channelName + ' รับชำระเงินในนามบริษัท เอสซีจึ ดิสทริบิวชั่น จำกัด'
                          : this.state.serviceCode === 'NEXTDIGI'
                          ? this.state.channelName + ' รับชำระเงินในนามบริษัท เน็กซเตอร์ ดิจิตอล จำกัด'
                          : 'ไม่สามารถระบุ SERVICE CODE ได้ กรุณาติดต่อเจ้าหน้าที่ หรือเลือกวิธีชำระเงินอื่น'}
                      </small>
                    </Col>
                  </Row>
                  {/* 
                    channel name รับชำระเงินในนาม ชื่อบริษัทตาม service code
                    SCGHOME - บริษัท เอสซีจึ ดิสทริบิวชั่น จำกัด
                    NEXTDIGI - บริษัท เน็กซเตอร์ ดิจิตอล จำกัด
                  */}
                </Col>
                <Col md={7}>
                  <div className="slip-title">
                    <span>ช่องทางการชำระเงิน</span>
                  </div>
                  <ol className="slip-list mb-2">
                    <li>Mobile Banking สามารถรับชำระเงินได้จาก Mobile Banking Application ทุกธนาคาร</li>
                    <li>เคาน์เตอร์ธนาคาร สามารถชำระได้ที่ธนาคารกรุงเทพ, ธนชาต, เกียรตินาคิน</li>
                  </ol>
                  <div className="info-bank">
                    <img src={iBBL} />
                    <span>Bangkok Bank (SERV CODE {this.state.serviceCode})</span>
                  </div>
                  <div className="info-bank">
                    <img src={iTBANK} />
                    <span>Thanachart Bank (BILLER ID {this.state.taxSuffix})</span>
                  </div>
                  <div className="info-bank">
                    <img src={iKaitnakin} />
                    <span>Kiatnakin Bank (BILLER ID {this.state.taxSuffix})</span>
                  </div>
                  <span>** ทางผู้รับชำระเงิน อาจเรียกเก็บค่าธรรมเนียมเพิ่มเติมจากราคาดังกล่าว</span>
                </Col>
              </Row>
            </Col>
            <Col />
          </Row>
          <Row className="information-container d-none d-print-flex">
            <Col md={6}>
              <div>
                <span className="slip-title slip-title-sm">ขั้นตอนการชำระเงินผ่าน Mobile Banking</span>
              </div>
              <ol className="slip-list">
                <li>Mobile Banking Application</li>
                <li>สแกนคิวอาร์โค้ด (QR-Code) เพื่อชำระค่าสินค้า</li>
                <li>ตรวจสอบข้อมูลการชำระเงินและกดยืนยัน</li>
              </ol>
              <span>* ชำระด้วยวิธีสแกนคิวอาร์โค้ดเท่านั้น</span>
            </Col>
            <Col md={6}>
              <div>
                <span className="slip-title slip-title-sm">ขั้นตอนการชำระเงินผ่านเคาน์เตอร์ธนาคาร</span>
              </div>
              <ol className="slip-list">
                <li>นำใบชำระเงินไปที่เคาน์เตอร์ธนาคารกรุงเทพ, ธนชาต, เกียรตินาคิน</li>
                <li>ยื่นบิลนี้ให้พนักงาน เพื่อทำการคีย์ข้อมูล</li>
                <li>ชำระค่าสินค้าและบริการตามยอดเงิน</li>
              </ol>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default inject('payment')(observer(BBLSlipPage));
