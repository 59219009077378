import '../assets/css/payment.css';
import React from 'react';
import qs from 'query-string';
import { Container, Row, Col } from 'reactstrap';
import PaymentResultCard from './../components/payment/PaymentResultCard';
import { T2P_ERROR_CODES, T2P_ERROR_ALERTS } from './../constants/errorConstants';
import iExclamationCircle from '../assets/img/icons/exclamation-circle.svg';

class PaymentResult extends React.Component {
  getAlertMessage = ({ method, status, shop, error_code }) => {
    let alert = { status, icon: 'far fa-times-circle', header: 'การชำระเงินไม่สำเร็จ', message: 'กรุณาติดต่อร้านค้าเพื่อตรวจสอบความถูกต้องของรายการชำระเงิน' };

    if (method === 'mobile_banking_opn') {
      switch (status) {
        case 'success':
          return { ...alert, icon: 'far fa-check-circle', header: 'การชำระเงินสำเร็จ', message: 'ขอบคุณสำหรับการชำระเงิน' };
        case 'pending':
          return { ...alert, icon: 'far fa-clock', header: 'รอการชำระเงิน', message: 'ขณะนี้กำลังรอการดำเนินการชำระเงิน หากท่านยังไม่ได้ชำระ กรุณาดำเนินการต่อ ตามช่วงเวลาที่ธนาคารกำหนด' };
        case 'expired':
          return { ...alert, image: iExclamationCircle, header: 'หมดเวลาในการชำระเงิน', message: 'คุณไม่ได้ชำระเงินภายในเวลาที่ธนาคารกำหนด กรุณาทำรายการชำระเงินใหม่อีกครั้ง' };
        default:
          return { ...alert, message: 'กรุณาทำรายการชำระเงินใหม่อีกครั้ง' };
      }
    } else {
      switch (status) {
        case 'success':
          return {
            ...alert,
            icon: 'far fa-check-circle',
            header: 'การชำระเงินสำเร็จ',
            message: `ขอบคุณที่ใช้บริการ ${shop ?? ''}`,
            subMessage: 'ลูกค้าสามารถขอยกเลิกรายการชำระเงินค่าสินค้าและบริการได้ภายใน 20:45 น.ของวันที่ทำรายการชำระ โดยกรุณาติดต่อร้านค้าเพื่อขอยกเลิกรายการ',
          };
        case 'pending':
          return { ...alert, icon: 'far fa-clock', header: 'รอการชำระเงิน' };
        default:
          // NL-1462
          if (error_code == T2P_ERROR_CODES.ChargeFail) {
            let err = T2P_ERROR_ALERTS[error_code];
            alert = { ...alert, ...err };
          }
          return alert;
      }
    }
  };

  componentDidMount() {
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage({
        event: 'payment_result',
      }, '*');
    }
  }

  render() {
    let query = qs.parse(this.props.location.search);
    let { website } = query;

    // validate url
    try {
      Boolean(new URL(website));
    } catch (e) {
      website = '';
    }

    const alert = this.getAlertMessage(query);
    return (
      <Container fluid className="payment-result-page">
        <Row className="justify-content-center align-items-center py-3 text-center vh-100">
          <Col xs={12} sm={8} md={6} lg={4}>
            <PaymentResultCard status={alert.status} header={alert.header} icon={alert.icon} image={alert.image} message={alert.message} shopUrl={website} subMessage={alert.subMessage} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PaymentResult;
