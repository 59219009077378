import React from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import Currency from '../../components/payment/Currency';
import bank from '../../config/bank';

const CreditCardModal = ({
  installment,
  method,
  modal,
  toggle,
  confirm,
  disabled,
  cardnumber,
  sum,
  ...props
}) => {
  const removeMask = (str) => {
    return str.split(' ').join('').replaceAll('_', '');
  };

  const cardnumberText = cardnumber ? removeMask(cardnumber).substr(12) : '';
  const bankData = installment.card.abbr ? bank[installment.card.abbr.toLowerCase()] : undefined;
  const term = installment.period;
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} contentClassName="creditcard-modal-content" {...props}>
        {/* <ModalHeader toggle={toggle} close={closeBtn} className="modal-payment-header" /> */}
        <ModalBody className="">
          <h6 className="text-blue">{method === 'installment' ? 'Installment (แบ่งชำระ)' : 'Credit Card (ชำระเต็มจำนวน)'}</h6>
          {method === 'installment' && (
            <Row className="d-flex align-items-center">
              <Col xs={4}>
                <small><b>Credit Card</b></small>
              </Col>
              <Col xs={8}>
                <small><b>{bankData?.nameTh ?? ''}</b></small><br />
                <small>แบ่งชำระ {term ?? ''} เดือน | <Currency number={+sum / term} /> บาท / เดือน</small>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={4}>
              <small><b>Card Number</b></small>
            </Col>
            <Col xs={8}>
              <small>{cardnumberText ? `**** **** **** ${cardnumberText}` : ''}</small>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <small><b>Amount</b></small>
            </Col>
            <Col xs={8}>
              <small><Currency number={method === 'installment' ? +sum / term : +sum} /> THB</small>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs={12} md={4}>
              <button className="btn btn-secondary btn-block" onClick={toggle}>กลับไป</button>
            </Col>
            <Col xs={12} md={8}>
              <button className="btn btn-primary btn-block mt-2 mt-md-0" onClick={confirm} disabled={disabled}>ยืนยันชำระเงิน</button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreditCardModal;
