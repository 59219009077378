import bay from '../assets/img/bay.png';
import bbl from '../assets/img/bbl.jpg';
import fcc from '../assets/img/fcc.jpg';
import ktc from '../assets/img/ktc.jpg';
import scb from '../assets/img/scb.jpg';
import kbank from '../assets/img/kbank.png';
import uob from '../assets/img/uob.jpeg';
import tbank from '../assets/img/tbank.jpg';
import ttb from '../assets/img/ttb.png';
import other from '../assets/img/other.jpg';

const bank = {
  bay: {
    logo: bay,
    name: 'Bank of Ayudhya (Krungsri)',
    nameTh: 'ธนาคารกรุงศรีอยุธยา',
  },
  bbl: {
    logo: bbl,
    name: 'Bangkok Bank',
    nameTh: 'ธนาคารกรุงเทพ',
  },
  fcc: {
    logo: fcc,
    name: 'Krungsri First Choice',
    nameTh: 'กรุงศรีเฟิร์สช้อยส์',
  },
  kbank: {
    logo: kbank,
    name: 'Kasikorn Bank',
    nameTh: 'ธนาคารกสิกรไทย',
  },
  ktc: {
    logo: ktc,
    name: 'Krungthai Bank',
    nameTh: 'ธนาคารกรุงไทย',
  },
  scb: {
    logo: scb,
    name: 'Siam Commercial Bank',
    nameTh: 'ธนาคารไทยพาณิชย์',
  },
  uob: {
    logo: uob,
    name: 'United Overseas Bank',
    nameTh: 'ธนาคารยูโอบี',
  },
  tbank: {
    logo: tbank,
    name: 'Thanachart Bank',
    nameTh: 'ธนาคารธนชาต',
  },
  ttb: {
    logo: ttb,
    name: 'TMBThanachart Bank',
    nameTh: 'ธนาคารทหารไทยธนชาต',
  },
  '2c2p': {
    logo: other,
    name: 'Other Bank',
    nameTh: 'ธนาคารอื่นๆ',
  },
};

module.exports = bank;
