import { createBrowserHistory } from 'history';

export const history = (window && window.document) ? createBrowserHistory() : {
  length: 0,
  action: {},
  location: {},
  push: () => {},
  replace: () => {},
  go: () => {},
  goBack: () => {},
  goForward: () => {},
  block: () => {},
  listen: () => {},
  createHref: () => {},
};
