import React from 'react';
import { Media } from 'reactstrap';
import Select from 'react-select';
import Currency from '../../components/payment/Currency';
import bank from '../../config/bank';

const InstallmentCard = ({
  bankInfo,
  card,
  selected,
  terms,
  period,
  total,
  onClick,
  onSelectedChange,
}) => {

  const options = terms.sort((a, b) => parseInt(a) - parseInt(b)).map((term) => {
    return {
      value: term,
      label: (<>{term} เดือน | ฿ <Currency number={+total / term} /> / เดือน</>),
    };
  });
  const selectedOption = options.find(opt => opt.value === period);
  let data = bank[card.abbr.toLowerCase()];
  return (
    <>
      <div className="pt-1">
        <button className={`btn-installment ${selected ? 'focus' : ''}`} onClick={!selected ? onClick : undefined}>
          <div className="row">
            <div className="col-3 text-center">
              <Media object alt="logo" width="48" src={data.logo}
                style={{
                  marginRight: 8,
                  borderRadius: 4,
                  padding: 0,
                  objectFit: 'contain',
                }} />
            </div>
            <div className="col-9 text-left">
              <span><b>{data.nameTh}</b></span><br />
              <span className="text-plan">เลือกแผนการชำระเงิน</span><br />
              {selected &&
                <Select options={options}
                  value={selectedOption ?? null}
                  onChange={(option) => onSelectedChange(option.value)}
                  placeholder="เลือกแผนการแบ่งชำระ" />}
            </div>
          </div>
        </button>
      </div>
    </>
  );
};

export default InstallmentCard;
