import Compressor from 'compressorjs';

class File {
  compressImage(file, maxHeight = 600) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      let compressed = new Compressor(file, {
        maxHeight: maxHeight,
        quality: 0.8,
        success(result) {
          console.log('File -> compressImage() -> success -> result', result);
          resolve(result);
        },
        error(err) {
          console.log('File -> compressImage() -> error -> msg', err.message);
          reject(err);
        },
      });
    });
  }
}

export const file = new File();
