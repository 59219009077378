import '../../assets/css/seller.css';
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import qs from 'query-string';

import { Container, Card, CardBody, CardTitle, Row, Col, Button, FormGroup, Label, Input, FormText, InputGroup, InputGroupAddon, CustomInput } from 'reactstrap';

import FilePicker from '../../components/forms/FilePicker';
import Number from '../../components/forms/Number';
import SellerRegisterKYCCompany from './SellerRegisterKYCCompany';
import SellerRegisterKYCPersonal from './SellerRegisterKYCPersonal';

class SellerRegisterPage extends Component {
  async componentDidMount() {
    let query = qs.parse(this.props.location.search);
    this.props.sellerRegister.fetchChannelByPublicUid(query.channel);
    this.props.bank.fetchBank();
  }

  changeTaxId(event) {
    this.props.sellerRegister.changeTaxId(event.target.value);
    this.props.sellerRegister.changeIsRegistered(null);
  }

  checkTaxId() {
    this.props.sellerRegister.checkTaxId();
  }

  changeSellerKYCType(type) {
    this.props.sellerRegister.changeSellerKYCType(type);
  }

  changeSellerName(event) {
    this.props.sellerRegister.changeSellerName(event.target.value);
  }

  /* No display for Seller interface
  changeSellerSaleType(type) {
    this.props.sellerRegister.changeSellerSaleType(type);
  }
  */

  /* No display for Seller interface
  changeSellerEmail(event) {
    this.props.sellerRegister.changeSellerEmail(event.target.value);
  }
  */

  changeBank(selected) {
    this.props.sellerRegister.changeBank(selected.value, selected.label);
  }

  changeBankAccountName(event) {
    this.props.sellerRegister.changeBankAccountName(event.target.value);
  }

  changeBankAccountNumber(event) {
    this.props.sellerRegister.changeBankAccountNumber(event.target.value);
  }

  changeBankAccountImage(files) {
    if (files && files.length) {
      if (files.length > 1) files.shift();
      this.props.sellerRegister.changeBankAccountImage(files[0]);
    }
  }

  changeSellerValue(event) {
    this.props.sellerRegister.changeSellerValue(event);
  }

  changeSellerPhoneNumber(event) {
    this.props.sellerRegister.changeSellerPhoneNumber(event.target.value);
  }

  changeDealerCode(event) {
    this.props.sellerRegister.changeDealerCode(event.target.value);
  }

  createSeller() {
    this.props.sellerRegister.createSeller();
  }

  render() {
    const { taxId, isRegistered, seller, loading, channel, channelSeller } = this.props.sellerRegister.getData();
    const { banks } = this.props.bank.getData();
    const bankOptions = banks.map(bank => ({ value: bank.code, label: bank.fullname_th }));

    const colStyle = { size: 9, offset: 1 };
    const bankCode = _.get(seller, 'sellers_account.bank_code');
    const currentBank = bankCode ? { value: seller.sellers_account.bank_code, label: seller.sellers_account.bank_name } : null;

    if (_.isEmpty(channel)) {
      return (
        <div className="seller-register-page">
          <Container className="seller-register-container py-5">
            <Card>
              <CardBody className="py-5">
                <CardTitle className="text-center">{loading ? <p className="text-muted">กำลังโหลดข้อมูล..</p> : <h5>ไม่พบข้อมูลร้านค้า</h5>}</CardTitle>
              </CardBody>
            </Card>
          </Container>
        </div>
      );
    }
    return (
      <div className="seller-register-page">
        <Container className="seller-register-container py-5">
          {!_.isEmpty(channel) && (
            <Card>
              <CardBody>
                <CardTitle>
                  <h4>ลงทะเบียนกับร้าน {channel.name}</h4>
                </CardTitle>
                <hr />
                <Row className="content my-3">
                  <Col md={colStyle}>
                    <FormGroup row className="align-items-center required">
                      <Label sm="5" className="control-label">
                        เลขประจำตัวประชาชน/นิติบุคคล
                        <br />
                        <small>(13 หลัก)</small>
                      </Label>
                      <Col sm="6">
                        <InputGroup>
                          <Number value={taxId || ''} onChange={this.changeTaxId.bind(this)} pattern="[0-9]*" maxLength={13} />
                          <InputGroupAddon addonType="append">
                            <Button color="primary" onClick={this.checkTaxId.bind(this)}>
                              <i className="fa fa-search" /> ค้นหา
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                {isRegistered !== null && !isRegistered && (
                  <Fragment>
                    {/* {isRegistered === null && <Fragment> */}
                    <Row className="mt-4">
                      <Col className="align-self-center">
                        <b>ข้อมูลผู้ขาย</b>
                      </Col>
                      <Col sm={{ size: 12 }}>
                        <hr />
                      </Col>
                    </Row>
                    <Row className="content my-3">
                      <Col md={colStyle}>
                        <FormGroup row className="required">
                          <Label sm="5" className="control-label">
                            ชื่อผู้ขาย
                          </Label>
                          <Col sm="6">
                            <Input value={seller.name || ''} onChange={this.changeSellerName.bind(this)} />
                          </Col>
                        </FormGroup>
                      </Col>
                      {/*  No display for Seller interface
                      <Col md={colStyle} className="mt-3">
                        <FormGroup row className="required">
                          <Label sm="5" className="control-label">
                            ประเภท
                          </Label>
                          <Col sm="6">
                            <CustomInput
                              type="radio"
                              id="sellerType"
                              name="sellerSaleType"
                              label="Channel"
                              checked={seller.type === 'channel'}
                              onChange={this.changeSellerSaleType.bind(this, 'channel')}
                            />
                            <CustomInput
                              type="radio"
                              id="sellerType2"
                              name="sellerSaleType"
                              label="Partner"
                              checked={seller.type === 'partner'}
                              onChange={this.changeSellerSaleType.bind(this, 'partner')}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md={colStyle} className="mt-3">
                        <FormGroup row>
                          <Label sm="5" className="control-label">E-Mail</Label>
                          <Col sm="6">
                            <Input value={seller.email || ''} name="email" onChange={this.changeSellerEmail.bind(this)} />
                          </Col>
                        </FormGroup>
                      </Col>
                      */}
                      <Col md={colStyle} className="mt-3">
                        <FormGroup row className="required">
                          <Label sm="5" className="control-label">
                            เบอร์โทรศัพท์ผู้ขาย
                          </Label>
                          <Col sm="6">
                            <Input
                              value={seller.phone_number || ''}
                              onChange={this.changeSellerPhoneNumber.bind(this)}
                              invalid={(seller.phone_number[0] != '0' && seller.phone_number.length == 10) || seller.phone_number.length == 9}
                              maxLength={10}
                              onKeyPress={e => {
                                let acceptedKeycode = [];
                                acceptedKeycode.push([48, 57]); // 0-9
                                acceptedKeycode.push([45, 45]); // -
                                let isValid = false;
                                acceptedKeycode.forEach(range => {
                                  if (range[0] <= e.charCode && e.charCode <= range[1]) isValid = true;
                                });
                                if (!isValid) e.preventDefault();
                              }}
                            />
                            <FormFeedback className="font-weight-normal">โปรดกรอกหมายเลขโทรศัพท์ให้ถูกต้อง</FormFeedback>
                          </Col>
                        </FormGroup>
                      </Col>
                      {/* <Col md={colStyle} className="mt-3">
                    <FormGroup row className="required">
                      <Label sm="5" className="control-label">ประเภท</Label>
                      <Col sm="6">
                        <Input value={seller.type || ''} readOnly />
                      </Col>
                    </FormGroup>
                  </Col> */}
                      <Col md={colStyle} className="mt-3">
                        <FormGroup row className="required">
                          <Label sm="5" className="control-label">
                            ธนาคาร
                          </Label>
                          <Col sm="6">
                            <Select
                              className="nexter-select"
                              classNamePrefix="react-select"
                              placeholder="เลือกธนาคาร..."
                              value={currentBank}
                              options={bankOptions}
                              onChange={this.changeBank.bind(this)}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md={colStyle} className="mt-3">
                        <FormGroup row className="required">
                          <Label sm="5" className="control-label">
                            ชื่อบัญชี
                          </Label>
                          <Col sm="6">
                            <Input value={seller.sellers_account.bank_account_name || ''} onChange={this.changeBankAccountName.bind(this)} />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md={colStyle} className="mt-3">
                        <FormGroup row className="required">
                          <Label sm="5" className="control-label">
                            หมายเลขบัญชี
                          </Label>
                          <Col sm="6">
                            <Number value={seller.sellers_account.bank_account_no || ''} onChange={this.changeBankAccountNumber.bind(this)} pattern="[0-9]*" maxLength={20} />
                            <FormText className="text-right">เฉพาะตัวเลขเท่านั้น</FormText>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md={colStyle} className="">
                        <FormGroup row className="align-items-center required">
                          <Label sm="5" className="control-label">
                            สำเนาสมุดบัญชี
                          </Label>
                          <Col sm="6" className="pt-3">
                            <FilePicker
                              src={seller.sellers_account.bank_account_image}
                              onDrop={this.changeBankAccountImage.bind(this)}
                              inputProps={{ multiple: false, accept: 'image/png, image/jpeg, .pdf' }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      {/* <Col md={colStyle} className="mt-3">
                    <FormGroup row>
                      <Label sm="5" className="control-label">Merchant Dealer Code (SCG wallet)</Label>
                      <Col sm="6">
                        <Input value={seller.sellers_account.dealer_code || ''} onChange={this.changeDealerCode.bind(this)} />
                      </Col>
                    </FormGroup>
                  </Col> */}
                      <Col md={colStyle} className="mt-3">
                        <FormGroup row className="required">
                          <Label sm="5" className="control-label">
                            ประเภทผู้ขาย (สำหรับ KYC)
                          </Label>
                          <Col sm="6">
                            <CustomInput
                              type="radio"
                              id="sellerTypeRadio"
                              name="sellerType"
                              label="บุคคลธรรมดา"
                              checked={seller.kyc_type === 'personal'}
                              onChange={this.changeSellerKYCType.bind(this, 'personal')}
                            />
                            <CustomInput
                              type="radio"
                              id="sellerTypeRadio2"
                              name="sellerType"
                              label="นิติบุคคล"
                              checked={seller.kyc_type === 'company'}
                              onChange={this.changeSellerKYCType.bind(this, 'company')}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    {seller.kyc_type === 'personal' && <SellerRegisterKYCPersonal />}
                    {seller.kyc_type === 'company' && <SellerRegisterKYCCompany />}
                  </Fragment>
                )}
                <hr />
                <Row>
                  <Col className="text-right">
                    <Button size="lg" color="primary" onClick={this.createSeller.bind(this)} disabled={loading || (isRegistered && !_.isEmpty(channelSeller))}>
                      <i className="fa fa-signature" /> ลงทะเบียน
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    );
  }
}

export default inject('sellerRegister', 'bank')(observer(SellerRegisterPage));
