import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import { Container, Row, Col, Card, CardHeader, CardBody, Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import CreditCardBox from '../../components/payment/CreditCardBox';
import BillPaymentBox from '../../components/payment/BillPaymentBox';
import InstallmentBox from '../../components/payment/InstallmentBox';
import LinePaymentBox from '../../components/payment/LinePaymentBox';
import PromptPayPaymentBox from '../../components/payment/PromptPayPaymentBox';
import GrabPayPaymentBox from '../../components/payment/GrabPayPaymentBox';
import CBDCPaymentBox from '../../components/payment/CBDCPaymentBox';
import ScgWalletBox from '../../components/payment/ScgWalletBox';
import ChangFamilyWalletBox from '../../components/payment/ChangFamilyWalletBox';
import MobileBankingOpnPaymentBox from '../../components/payment/MobileBankingOpnPaymentBox';
// import KTCSCG from '../../components/payment/KTCSCG';
// import CreditCardMultiBox from '../../components/payment/CreditCardMultiBox';
import PaymentInfo from '../../components/payment/PaymentInfo';
import PaymentMethod from '../../components/payment/PaymentMethod';
import QRBox from '../../components/payment/QRBox';
import { config } from '../../config';
import bank from '../../config/bank';
import Promotion from '../../components/payment/Promotion';
import PaymentResultCard from '../../components/payment/PaymentResultCard';

const WizardComponent = ({ ...props }) => {
  return (
    <>
      <Nav className={`sw-wizard ${props.className ? props.className : ''}`} tabs>
        {props.steps.map((item, i, arr) => {
          return (
            <NavItem className="step-wrapper" key={i}>
              <NavLink className={`step step-${i} ${item.active && !item.done ? 'active' : ''} ${item.done ? 'done' : ''} ${i === 0 ? 'not-before' : ''} ${i === arr.length - 1 ? 'not-after' : ''}`}>
                <div className="step-frame-text">
                  <span className="step-text">{item.done ? <i className="fas fa-check" /> : item.step ?? i + 1}</span>
                </div>
              </NavLink>
              <div className="text-center step-title">{item.title}</div>
            </NavItem>
          );
        })}
      </Nav>
    </>
  );
};

class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [
        { step: 1, title: 'เลือกวิธีการชำระเงิน', active: true, done: false },
        { step: 2, title: 'การชำระเงิน', active: false, done: false },
        { step: 3, title: 'เสร็จสิ้น', active: false, done: false },
      ],
      alert: { status: 'created', message: '', icon: '' },
      triggerSubmit: false,
      loading: true,
    };
  }
  async componentDidMount() {
    let uid = this.props.match.params.uid;
    localStorage.setItem('payment_uid', uid);
    this.props.payment.fetchPaymentPromotions(uid);
    await this.props.payment.fetchPayment(uid);
    this.setState({ ...this.state, loading: false });
    const data = this.props.payment.getData();
    this.getPaymentMethod(data.payment.status, data.payment.expire_at);
    if (data.payment?.chang_family_payment_info) {
      this.props.changFamilyWallet.changeCardNumber(data.payment.chang_family_payment_info.cardref);
    }
  }
  changeStateStep(stepId, type) {
    const steps = this.state.steps.map(i => {
      if (i.step === stepId) {
        switch (type) {
          case 'active':
            i.active = true;
            break;
          case 'done':
            i.done = true;
            break;
        }
      }
      return i;
    });
    this.setState({ ...this.state, steps });
  }
  doneStateStep = () => {
    this.changeStateStep(1, 'done');
    this.changeStateStep(2, 'done');
    this.changeStateStep(3, 'done');
  };
  processStateStep = () => {
    this.changeStateStep(1, 'done');
    this.changeStateStep(2, 'active');
  };
  preSubmit = () => {
    this.setState({ ...this.state, triggerSubmit: true });
  };
  getPaymentForm() {
    let data = this.props.payment.getData();
    let query = qs.parse(this.props.location.search);
    if (config.features.qr && data.payment.method === 'QR') {
      data.payment.method = 'qr_box';
    }
    // if (config.features.multiGateway && data.payment.method === 'credit_card') {data.payment.method = 'credit_card_multi'; };
    switch (data.payment.method) {
      case 'credit_card':
        return <CreditCardBox token={query.token} triggerSubmit={this.state.triggerSubmit} />;
      // case 'credit_card_multi':
      //   return <CreditCardMultiBox token={query.token} />;
      case 'qr_box':
        return <QRBox token={query.token} />;
      case 'bill_payment_mobile':
        return <BillPaymentBox token={query.token} type="mobile" />;
      case 'bill_payment':
        return <BillPaymentBox token={query.token} type="counter" />;
      case 'line':
        return <LinePaymentBox token={query.token} type="counter" />;
      case 'installment':
        return <InstallmentBox token={query.token} />;
      case 'scg_wallet':
        return <ScgWalletBox />;
      case 'chang_family_wallet':
        return <ChangFamilyWalletBox />;
      case 'promptpay':
        return <PromptPayPaymentBox />;
      case 'grabpay':
        return <GrabPayPaymentBox />;
      case 'cbdc':
        return <CBDCPaymentBox />;
      case 'mobile_banking_opn':
        return <MobileBankingOpnPaymentBox />;
      // case 'ktc_scg':
      //   return <KTCSCG payment={data.payment} />;
      default:
        return '';
    }
  }
  getPaymentMethod(status, expireAt) {
    const today = new Date();
    const expire = new Date(expireAt);
    if ((expire < today && status === 'created') || (expire < today && status === 'cancel') || status === 'voided' || status === 'refunded') {
      status = 'expired';
    }

    let alert = { status: '', message: '', icon: '' };
    if (status === 'success' || status === 'settled' || status === 'pending_settle' || status === 'mismatch_settled') {
      alert = { status, message: 'The payment has been successfully.', icon: 'fas fa-check-circle' };
      this.doneStateStep();
    } else if (status === 'failed') {
      alert = { status, message: 'This payment was failed.', icon: 'fas fa-exclamation-circle' };
      this.processStateStep();
    } else if (status === 'expired') {
      alert = { status, message: 'This payment has been expired.', icon: 'fas fa-exclamation-circle' };
      this.processStateStep();
    } else if (status === 'pending') {
      alert = { status, message: 'ลิงค์การชำระเงินนี้ได้ถูกใช้งานแล้ว กรุณาชำระเงินตามช่องทางที่ท่านเลือก หรือหากต้องการเปลี่ยนช่องทางการชำระเงิน กรุณาติดต่อ Admin ร้านค้า', icon: 'fas fa-sync-alt' };
      this.processStateStep();
    }
    this.setState({ ...this.state, alert });
  }

  render() {
    let { payment, promotions: paymentPromotions } = this.props.payment.getData();

    // NL-100 display promotions
    const channelPromotions = paymentPromotions ?? [];
    const promotions = channelPromotions
      .filter(c => c.promotion_payment_method && c.promotion_payment_method.find(ppm => ppm.payment_method.code === payment.method && ppm.is_active))
      .map(c => {
        return {
          thumbnail: c.thumbnail_url ?? '',
          img: c.file_url ?? '',
          bank: c.bank ? (bank[c.bank.toLowerCase()] || {}).nameTh : '',
          name: c.promotion_name ?? '',
          desc: c.promotion_desc ?? '',
        };
      });

    return (
      <>
        {this.state.loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100 py-3">
            <Spinner color="primary" />
          </div>
        ) : typeof payment.id === 'undefined' ? (
          <Container className="my-5">
            <Row className="justify-content-center text-center">
              <Col xs={12} md={5}>
                <PaymentResultCard status="failed" header="ลิงค์ชำระเงินไม่ถูกต้อง" icon="far fa-times-circle" message="กรุณาติดต่อร้านค้าเพื่อตรวจสอบความถูกต้องของรายการชำระเงิน" />
              </Col>
            </Row>
          </Container>
        ) : (
          <Container className="payment-wrapper">
            <Row className="d-flex justify-content-center py-3">
              <Col sm="12" md="7">
                <WizardComponent steps={this.state.steps} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              {!this.state.alert.status && (
                <Col sm="12" md="6" lg="6">
                  <Card>
                    <CardHeader className="text-center gradient-header text-white py-3">
                      <p className="font-big m-0">โปรดเลือกวิธีการชำระเงิน</p>
                    </CardHeader>
                    <CardBody className="payment-info-body">
                      <div className="pb-2">
                        <PaymentMethod changeStateStep={this.changeStateStep.bind(this)} />
                      </div>
                      {this.getPaymentForm()}
                      {!!promotions?.length && <Promotion promotions={promotions} />}
                    </CardBody>
                  </Card>
                </Col>
              )}
              <Col sm="12" md="6" lg="6" className="mt-3 mt-md-0">
                {/* <CardPaymentInfo /> */}
                <PaymentInfo alert={this.state.alert} method={payment.method} preSubmit={this.preSubmit.bind(this)} />
              </Col>
            </Row>
            {/* <Row>
                <Col md="6" className="order-md-2" style={{ marginTop: 15 }}>
                  <PaymentInfo />
                </Col>

                <Col md="6" style={{ marginTop: 15 }}>
                  <Card>
                    <CardBody style={{ paddingBottom: 0 }}>
                      { payment.channel &&
                      <Fragment>
                        <Row>
                          <Col className="mx-auto text-center">
                            <img src={payment.channel.logo_url || logo} alt="" height="50" />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h6 className="text-center mt-3">{payment.channel.name}</h6>
                          </Col>
                        </Row>
                      </Fragment>
                      }
                      <hr />
                      <PaymentDisplay
                        status={payment.status}
                        expireAt={payment.expire_at}
                        getPaymentForm={this.getPaymentForm.bind(this)}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
          </Container>
        )}
      </>
    );
  }
}

export default inject('payment', 'channel', 'changFamilyWallet')(observer(PaymentPage));
