import React, { Fragment } from 'react';
import numeral from 'numeral';

const Currency = ({ prefix, suffix, number }) => (
  <Fragment>
    {prefix || ''} { numeral(number).format('0,0.00') } { suffix || ''}
  </Fragment>
);

export default Currency;
