import React, { useState } from 'react';
import PromotionModal from './PromotionModal';

const Promotion = ({ promotions }) => {
  const [modal, setModal] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState({});

  const toggle = () => setModal(!modal);

  const clickPromotion = (promotion) => {
    setSelectedPromotion(promotion);
    toggle();
  };

  return (
    <div className="mt-3">
      <div className="py-2"><h6>รายละเอียดโปรโมชั่นธนาคาร</h6></div>
      <div className="d-flex align-items-center promotion-wrapper">
        <div className="promotion-container">
          {promotions.map((promotion, i) => {
            return (
              <div
                key={i}
                className="promotion-card"
                onClick={() => clickPromotion(promotion)}
              >
                <div
                  className="item"
                  style={{ backgroundImage: `url(${promotion.thumbnail || promotion.img})` }}
                />
                <div className="promotion-detail">
                  <span className="name">{promotion.name}</span>
                  {promotion.bank !== '' && <span className="bank">{promotion.bank}</span>}
                  <p className="description">
                    {promotion.desc}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <PromotionModal modal={modal} toggle={toggle} promotion={selectedPromotion} />
    </div>
  );
};

export default Promotion;
