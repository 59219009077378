import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { FormGroup } from 'reactstrap';
import iChangFamilyCard from '../../assets/img/logos/logo-ChangFamilyCard.png';

class ChangFamilyWalletBox extends Component {
  render() {
    let changFamilyWallet = this.props.changFamilyWallet.getData();

    return (
      <Fragment>
        <hr className="custom-hr" />
        <div className="py-2">
          <h6>ชำระด้วย Chang Family Wallet</h6>
        </div>
        <div className="d-flex flex-wrap pb-3">
          <img src={iChangFamilyCard} height="80px" className="mb-1 mr-2" />
        </div>
        <FormGroup>
          <div className="alert alert-static-input" role="alert">
            <label className="font-small mb-0">Card No.(Ref1) 12 หลัก</label>
            <div className="card-number font-medium">{changFamilyWallet.card_number}</div>
          </div>
        </FormGroup>
      </Fragment>
    );
  }
}

export default inject('changFamilyWallet')(observer(ChangFamilyWalletBox));
