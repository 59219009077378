import swal from 'sweetalert2';
import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { history } from '../utils/history';
import { localStorage } from '../utils/localStorage';
import {
  KEY_PAYMENT_UID,
  KEY_CARD_NUMBER_CHANG,
  KEY_PHONE_NUMBER_CHANG,
  KEY_EXPIRY_DISABLE_OTP_CHANG,
  KEY_EXPIRY_DISABLE_CHARGE_CHANG,
  OTP_FREEZE_TIME,
  CHARGE_FREEZE_TIME,
} from './../constants/paymentConstants';
import { T2P_ERROR_CODES, T2P_ERROR_ALERTS, SYSTEM_ERROR_ALERTS, SYSTEM_ERROR_MSG } from './../constants/errorConstants';

export class ChangFamilyWalletStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      loadingRequestOtp: false,
      loadingChargeWallet: false,
      disabledSendOtpButton: false,
      disabledSendOtpButtonExpiredAt: this.getExpiryDisabledRequestOtpTime(),
      card_number: '',
      otp_number: '',
    });
  }

  changeCardNumber(value) {
    this.card_number = value;
    localStorage.setItem(KEY_CARD_NUMBER_CHANG, value);
  }

  changeOTPNumber(value) {
    this.otp_number = value;
  }

  getExpiryDisabledRequestOtpTime() {
    let currentExpiryTime = parseInt(localStorage.getItem(KEY_EXPIRY_DISABLE_OTP_CHANG)) || 0;
    return currentExpiryTime;
  }

  getExpiryDisabledChargeTime() {
    let currentExpiryTime = parseInt(localStorage.getItem(KEY_EXPIRY_DISABLE_CHARGE_CHANG)) || 0;
    return currentExpiryTime;
  }

  getPhoneNumber() {
    let phoneNumber = localStorage.getItem(KEY_PHONE_NUMBER_CHANG);
    return phoneNumber;
  }

  toggleSendOtpButton(status) {
    this.disabledSendOtpButton = status;
  }

  clearStorageData() {
    localStorage.removeItem(KEY_CARD_NUMBER_CHANG);
    localStorage.removeItem(KEY_PHONE_NUMBER_CHANG);
    localStorage.removeItem(KEY_PAYMENT_UID);
    localStorage.removeItem(KEY_EXPIRY_DISABLE_OTP_CHANG);
    localStorage.removeItem(KEY_EXPIRY_DISABLE_CHARGE_CHANG);
  }

  showErrorModal(res) {
    let err = res.code ? SYSTEM_ERROR_ALERTS[res.code] || T2P_ERROR_ALERTS[res.code] || {} : SYSTEM_ERROR_MSG;
    swal
      .fire({
        type: !err.imageUrl ? err.type || 'warning' : null,
        title: err.header,
        text: err.message || res.message,
        imageUrl: err.imageUrl,
        confirmButtonText: (err.backToMerchant && res.website_url ? 'กลับสู่ร้านค้า' : null) || err.buttonText || 'OK',
      })
      .then(result => {
        if (result.value) {
          if (err.backToMerchant && res.website_url) {
            window.location.href = res.website_url;
          }
        }
      });
  }

  async requestOTP({ redirect = false } = {}) {
    if (this.loadingRequestOtp) {
      return;
    }
    let timeNow = new Date();
    let currentExpiryTime = this.getExpiryDisabledRequestOtpTime();
    if (currentExpiryTime > timeNow) {
      redirect && history.push(`/otp`);
      return;
    }
    try {
      this.loadingRequestOtp = true;
      let cardNumber = localStorage.getItem(KEY_CARD_NUMBER_CHANG);
      let uid = localStorage.getItem(KEY_PAYMENT_UID);
      let body = {
        card_number: cardNumber,
        uid,
      };

      let url = `${config.npay.apiUrl}/otp/changfamilywallet`;
      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        let expiryTime = new Date().getTime() + OTP_FREEZE_TIME;
        localStorage.setItem(KEY_EXPIRY_DISABLE_OTP_CHANG, expiryTime);
        // customer phone number - NL-1460
        if (body.phone) {
          localStorage.setItem(KEY_PHONE_NUMBER_CHANG, body.phone);
        } else {
          localStorage.removeItem(KEY_PHONE_NUMBER_CHANG);
        }
        redirect && history.push(`/otp`);
      } else if (response.status === 429) {
        let body = await response.json();
        this.disabledSendOtpButtonExpiredAt = body.expired_at;
        localStorage.setItem(KEY_EXPIRY_DISABLE_CHARGE_CHANG, body.expired_at);
        let res = { ...body };
        this.showErrorModal(res);
      } else {
        let body = await response.json();
        let res = { ...body, code: body.data?.responseCode ?? body.code, message: body.data?.responseDesc ?? body.message };
        this.showErrorModal(res);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingRequestOtp = false;
    }
  }

  async chargeChangFamilyWallet() {
    if (this.loadingChargeWallet) {
      return;
    }
    let timeNow = new Date();
    let currentExpiryTime = this.getExpiryDisabledChargeTime();
    if (currentExpiryTime > timeNow) {
      return;
    }
    try {
      this.loadingChargeWallet = true;
      let otp = this.otp_number;
      let uid = localStorage.getItem(KEY_PAYMENT_UID);
      let cardNumber = localStorage.getItem(KEY_CARD_NUMBER_CHANG);

      let body = {
        uid,
        card_number: cardNumber,
        otp,
      };

      let url = `${config.npay.apiUrl}/charges/changfamilywallet`;
      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        console.log('body:\n', body);
        if (body.data.status === 'success') {
          window.location.href = body.data.postback_url;
          this.clearStorageData();
        } else {
          swal.fire({
            type: 'error',
            title: 'Error',
            text: body.data.failure_reason,
          });
        }
      } else {
        let body = await response.json();
        console.log('body:\n', body);

        // NL-1500
        if (body.code == T2P_ERROR_CODES.VerifyOTPFailFreez) {
          let expiryTime = new Date().getTime() + CHARGE_FREEZE_TIME;
          localStorage.setItem(KEY_EXPIRY_DISABLE_CHARGE_CHANG, expiryTime);
        }

        if (body.code == T2P_ERROR_CODES.ChargeFail) {
          history.push(`/thankyou?status=fail&error_code=${body.code}&website=${body.website_url}`);
        } else {
          this.showErrorModal(body);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingChargeWallet = false;
    }
  }
}
