import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Row, Col, Card, Container, CardHeader, CardBody, FormGroup, Label, Input, Button, InputGroup, InputGroupAddon } from 'reactstrap';

const PasswordInput = ({ type, onChange, showHide }) => {
  return (
    <InputGroup>
      <Input type={type} onChange={onChange} />
      <InputGroupAddon addonType="append">
        <Button className="m-0 btn-neutral border" onClick={showHide}>
          <i className="fa fa-eye-slash fa-fw" />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

class SetPassword extends Component {
  async componentDidMount() {
    let refid = this.props.match.params.refid;
    this.props.user.refid = refid;
    this.props.user.getUserByRefId(refid);
    // this.props.user.linkLifeTimer();
  }
  changePassword(event) {
    this.props.user.changePassword(event.target.value);
  }

  changeNewPassword(event) {
    this.props.user.changeNewPassword(event.target.value);
  }

  changeConfirmNewPassword(event) {
    this.props.user.changeConfirmNewPassword(event.target.value);
  }

  updatePassword() {
    this.props.user.updatePassword();
  }

  showHide() {
    this.props.user.toggleShowHide();
  }
  showTimmer() {
    // this.props.user.reset_timmer;
  }

  render() {
    const { user } = this.props.user.getData();
    return (
      <Container style={{ marginBottom: 30 }}>
        <Row>
          <Col md={{ size: 9, offset: 2 }}>
            <Card>
              <CardHeader className="text-center">
                ตั้งรหัสผ่าน
              </CardHeader>
              <CardBody>

                <FormGroup>
                  <Label>เวลาที่เหลือ</Label>
                  <Input value={user.reset_timmer || ''} readOnly={!false} />
                </FormGroup>
                <FormGroup>
                  <Label>รหัสผ่านใหม่</Label>
                  <PasswordInput
                    type={this.props.user.input_type}
                    onChange={this.changeNewPassword.bind(this)}
                    showHide={this.showHide.bind(this)} />
                </FormGroup>

                <FormGroup>
                  <Label>ยืนยันรหัสผ่านใหม่</Label>
                  <PasswordInput
                    type={this.props.user.input_type}
                    onChange={this.changeConfirmNewPassword.bind(this)}
                    showHide={this.showHide.bind(this)} />
                </FormGroup>

                <hr />
                <Button block onClick={this.updatePassword.bind(this)}>
                  <i className="fa fa-key" /> เปลี่ยนรหัสผ่าน
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col md={{ size: 9, offset: 2 }}>
            <Card >
              <CardHeader className="text-center">
                    Password Policy
              </CardHeader>
              <hr />
              <CardBody className="pt-0">
                <Row>
                  <Col color="light">
                    {<div className="mt-2 text-muted" style={{ fontSize: '12px' }}>
                      <b>เงื่อนไข </b><br />
                      <p className="mt-1">
                      - กำหนดรหัสใหม่ภายในเวลาที่กำหนด 5นาที นับจากที่ระบบ ออก Email<br />
                      - ความยาวไม่น้อยกว่า 8 ตัวอักษร<br />
                      </p>
                    </div>}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    );
  }
}

export default inject('user')(observer(SetPassword));
