import swal from 'sweetalert2';
import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { KEY_EXPIRY_DISABLE_CHARGE_CREDIT, KEY_EXPIRY_DISABLE_CHARGE_CREDIT_IPP } from '../constants/paymentConstants';

export class PaymentStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      payment: {
        // method: 'credit_card',
        method: '',
        total: 0,
        items: [],
        qrOrder: '',
        uid: '',
      },
      creditcard: {
        focused: 'number',
        name: '',
        number: '',
        cvc: '',
        expiry: '',
        expiryM: '',
        expiryY: '',
        type: '',
        issue: '',
      },
      billpayment: {
        agentCode: 'KBANK',
        channelCode: 'MOBILEBANKING',
      },
      installment: {
        card: {
          abbr: '',
        },
        period: 0,
      },
      bin: {
        card_id: 0,
        card_bin: '',
        card_product: '',
        card_type: '',
        card_bank: '',
        created_by: '',
        created_at: '',
        updated_by: '',
        updated_at: '',
      },
      promotions: [],
      disabledChargeCreditExpiredAt: this.getExpiryDisabledChargeCreditTime(),
      disabledChargeCreditIppExpiredAt: this.getExpiryDisabledChargeCreditIppTime(),
      mobile_banking_opn: {
        selectedMethodCode: ''
      },
    });
  }

  getExpiryDisabledChargeCreditTime() {
    let currentExpiryTime = parseInt(localStorage.getItem(KEY_EXPIRY_DISABLE_CHARGE_CREDIT)) || 0;
    return currentExpiryTime;
  }

  changeExpiryDisabledChargeCreditTime(time = 0) {
    this.disabledChargeCreditExpiredAt = time;
    localStorage.setItem(KEY_EXPIRY_DISABLE_CHARGE_CREDIT, time);
  }

  getExpiryDisabledChargeCreditIppTime() {
    let currentExpiryTime = parseInt(localStorage.getItem(KEY_EXPIRY_DISABLE_CHARGE_CREDIT_IPP)) || 0;
    return currentExpiryTime;
  }

  changeExpiryDisabledChargeCreditIppTime(time = 0) {
    this.disabledChargeCreditIppExpiredAt = time;
    localStorage.setItem(KEY_EXPIRY_DISABLE_CHARGE_CREDIT_IPP, time);
  }

  async fetchPayment(uid) {
    try {
      this.payment.uid = uid;
      let currentTime = new Date().getTime(); // disable cache NL-1444
      let url = `${config.npay.apiUrl}/payments/${uid}?v=${currentTime}`;
      let response = await http.get(url);

      if (response.status === 200) {
        let body = await response.json();
        this.payment = body.data;
        this.payment.method = '';
      }
    } catch (err) {
      console.error(err);
    }
  }

  async fetchPaymentPromotions(uid) {
    try {
      this.payment.uid = uid;
      let url = `${config.npay.apiUrl}/payments/${uid}/promotions`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.promotions = body.data || [];
      }
    } catch (err) {
      console.error(err);
    }
  }

  async chkBinCard(binCard, options = {}) {
    try {
      let body = {
        cardBin: binCard,
        uid: this.payment.uid,
      };

      let url = `${config.npay.apiUrl}/payments/chk/bincard/credit-card`;
      if (this.payment.method === 'installment') {
        url = `${config.npay.apiUrl}/payments/chk/bincard/installment`;
      }

      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.bin = body.data;
        this.creditcard.type = body.data ? body.data.card_type : '';
        this.creditcard.issue = body.data ? body.data.card_bank : '';
        try {
          if (body.data.card_bank === 'BBL') {
            this.creditcard.number = this.creditcard.number.split(' ').join('');
            this.creditcard.expiryM = this.creditcard.expiry.substring(0, 2);
            this.creditcard.expiryY = '20' + this.creditcard.expiry.substring(3, 5);
          }
          if (this.creditcard.type === 'MasterCard') {
            this.creditcard.type = 'Master';
          }
        } catch (err) {
          console.error(err);
        }
        if (!body.data && this.payment.method === 'installment') {
          swal.fire({
            type: 'warning',
            title: 'Warning',
            text: 'Card type not support (A1021)',
          });
          return false;
        }
        return true;
      } else {
        let body = await response.json();
        if (response.status === 429) {
          if (this.payment.method === 'credit_card') {
            this.changeExpiryDisabledChargeCreditTime(body.expired_at);
          } else if (this.payment.method === 'installment') {
            this.changeExpiryDisabledChargeCreditIppTime(body.expired_at);
          }
          options.onLocked && options.onLocked();
        }
        swal.fire({
          type: 'warning',
          title: 'Warning',
          text: body.message,
        });

        return false;
      }
    } catch (err) {
      console.error(err);
    }
  }

  changePaymentMethod(method) {
    this.payment.method = method;
  }

  selectBillPaymentChannel(agentCode, channelCode) {
    this.billpayment.agentCode = agentCode;
    this.billpayment.channelCode = channelCode;
  }

  changeCardName(name) {
    this.creditcard.name = name.toUpperCase();
    this.creditcard.focused = 'name';
  }

  changeCardNumber(number) {
    this.creditcard.number = number;
    this.creditcard.focused = 'number';
  }

  changeCardExpiry(expiry) {
    this.creditcard.expiry = expiry;
    this.creditcard.focused = 'expiry';
  }

  changeCardCVC(cvc) {
    this.creditcard.cvc = cvc;
  }

  changeInstallmentCard(card) {
    let terms = card.terms?.split(',');
    this.installment.period = terms?.length ? +terms[0] : 0;
    this.installment.card = card;
  }

  changeInstallmentPeriod(period) {
    this.installment.period = period;
  }

  changeGateway(gateway) {
    this.installment.card = gateway;
  }

  changeMobileBankingOpnMethod(code) {
    this.mobile_banking_opn.selectedMethodCode = code
  }
}
