import React, { Component, Fragment } from 'react';
import { OTP_SIZE } from './../../constants/paymentConstants';
import NexterButton from '../Button/NexterButton';

class SendOTPButton extends Component {
  constructor(props) {
    super(props);
    this.countDownTimerId = 0;
    this.state = {
      remainingTime: 0,
    };
  }

  componentDidMount() {
    this.countDown();
  }

  countDown() {
    let remainingTime = this.calculateRemainingTime();
    this.setState({ remainingTime });
    if (remainingTime > 0) {
      this.props.onTimeStart();
    }
    clearInterval(this.countDownTimerId);
    this.countDownTimerId = setInterval(() => {
      let remainingTime = this.calculateRemainingTime();
      this.setState({ remainingTime });
      if (remainingTime <= 0) {
        this.props.onTimeOut();
        clearInterval(this.countDownTimerId);
      } else {
        this.props.onTimeStart();
      }
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.countDown();
    }
  }

  componentWillUnmount() {
    this.props.onTimeOut();
    clearInterval(this.countDownTimerId);
  }

  calculateRemainingTime() {
    let timeNow = new Date().getTime();
    let remainingTime = Math.floor((this.props.expiryTime - timeNow) / 1000);
    return remainingTime;
  }

  render() {
    let { loading, otp } = this.props;
    let { remainingTime } = this.state;
    return (
      <Fragment>
        <NexterButton color="primary" className="btn-block text-nowrap" disabled={remainingTime > 0 || loading || otp.length !== OTP_SIZE} onClick={this.props.onClick.bind(this)}>
          {loading ? `กรุณารอสักครู่` : `ยืนยันรหัส OTP`}
        </NexterButton>
      </Fragment>
    );
  }
}
export default SendOTPButton;
