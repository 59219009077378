import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import Swal from 'sweetalert2';

import { config } from '../../config';

import CreditCard from './CreditCard';
import InstallmentCard from './InstallmentCard';
// import InstallmentPeriod from './InstallmentPeriod';

class InstallmentBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: '',
    };
    this.inputCard = React.createRef();
  }

  changeInstallmentPeriod(period) {
    this.props.payment.changeInstallmentPeriod(period);
  }

  changeInstallmentCard(card) {
    this.props.payment.changeInstallmentCard(card);
  }

  async verifyCardBin() {
    console.log('verifyCardBin');
    let payment = this.props.payment.getData();
    let cardNumber = payment.creditcard.number.replace(/[^0-9]/g, '');

    if (cardNumber.length !== 16) {
      Swal.fire({
        type: 'warning',
        title: 'หมายเลขบัตรไม่ถูกต้อง',
      });
      return false;
    }

    let passed = await this.props.payment.chkBinCard(cardNumber);
    if (passed) {
      this.encryptCreditCard(this);
    }
  }

  encryptCreditCard() {
    My2c2p.getEncrypted('2c2p-payment-form', (encryptedData, errCode, errDesc) => {
      console.log('submit form');
      console.log(encryptedData);

      if (errCode !== 0) alert(`${errDesc} (${errCode})`);
      else {
        let payment = this.props.payment.getData();
        let cardHolderName = payment.creditcard.name;

        let data = {
          uid: payment.payment.uid,
          card_holder_name: cardHolderName,
          installment_period: payment.installment.period,
          card_encrypted: encryptedData.encryptedCardInfo,
        };

        let url = `${config.npay.apiUrl}/charges/installment`;
        let method = 'post';
        let headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.props.token}`,
        };

        fetch(url, { method, headers, body: JSON.stringify(data) })
          .then(response => response.json())
          .then(body => {
            if (body.statusCode === 500) {
              Swal.fire({
                title: 'Error!',
                text: body.message,
                type: 'error',
                confirmButtonText: 'OK',
              });
              throw new Error(body.message);
            }
            let payload = body.data.payload;
            this.setState({ payload }, () => {
              document.getElementById('ccpp-form').submit();
            });
          }).catch((error) => {
            console.log(error);
          });
      }
    });
  }

  render() {
    let data = this.props.payment.getData();
    let payment = data.payment;
    let installment = data.installment;
    let discount = payment.items.reduce((discount, item) => discount + (+item.discount), 0);

    // let cards = payment.channel.installment.cards.map(card => {
    //   return (
    //     <Col key={card.abbr} md={6} xs={12}>
    //       <InstallmentCard
    //         card={card}
    //         selected={installment.card.abbr === card.abbr}
    //         onClick={this.changeInstallmentCard.bind(this, card)}
    //       />
    //     </Col>
    //   );
    // });
    // const icards = [{ abbr: 'kbank' }, { abbr: 'bbl' }];
    const icards = payment.channel.installment.cards;
    let terms = [];
    if (installment.card.terms) {
      terms = installment.card.terms.split(',').map(i => +i);
      terms.sort((a, b) => a - b);
    }
    
    let cards = icards.map((card, i) => {
      return (
        <Col key={i} xs={12}>
          <InstallmentCard
            card={card}
            terms={terms}
            period={installment.period}
            total={+payment.total - +discount}
            selected={installment.card.abbr === card.abbr}
            onClick={this.changeInstallmentCard.bind(this, card)}
            onSelectedChange={this.changeInstallmentPeriod.bind(this)}
          />
        </Col>
      );
    });

    // let periods = terms.map(term => {
    //   term = +term;
    //   return (
    //     <Col key={`${installment.card.abbr}-${term}`} md={6} xs={12}>
    //       <InstallmentPeriod
    //         // key={`${installment.card.abbr}-${term}`}
    //         period={term}
    //         total={+payment.total - +discount}
    //         selected={installment.period === term}
    //         onClick={this.changeInstallmentPeriod.bind(this, term)}
    //       />
    //     </Col>
    //   );
    // });

    return (
      <Fragment>
        {/* <CardTitle className="text-center mt-3" style={{ marginBottom: 15 }}>
          กรุณาเลือกบัตรและจำนวนเดือนสำหรับผ่อนชำระ
        </CardTitle> */}

        <hr className="custom-hr" />
        <div className="py-2"><h6>ชำระเงินด้วย Installment (แบ่งชำระ)</h6></div>
        {cards.length
          ? (
            <Row className="pb-4">{cards}</Row>
          ) : (
            <div><label>ไม่พบรายการผ่อนชำระ</label><hr className="custom-hr" /></div>
          )
        }

        {/* { periods.length ? <hr /> : ''}
        <Row>{periods.length ? periods : ''}</Row> */}

        {/* <CreditCard formId="2c2p-payment-form" displayLogo />
        <hr />
        <Button block color="primary"
          style={{ backgroundColor: '#e92a2a', borderColor: '#e92a2a' }}
          disabled={installment.period === 0}
          onClick={this.verifyCardBin.bind(this)}>
          ฿ ผ่อนชำระ {installment.period ? `${installment.period} เดือน` : ''}
        </Button>
        <form id="ccpp-form" method="post" action={config.ccpp.securePayUrl}>
          <input type="hidden" name="paymentRequest" value={this.state.payload} />
        </form>
        <Promotion /> */}

        {icards.find(c => installment.card.abbr === c.abbr) && (
          <>
            <CreditCard formId="2c2p-payment-form" displayLogo />
            {/* <form id="ccpp-form" method="post" action={config.ccpp.securePayUrl}>
              <input type="hidden" name="paymentRequest" value={this.state.payload} />
            </form> */}
          </>
        )}
      </Fragment>
    );
  }
}

export default inject('payment')(observer(InstallmentBox));
