import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const PromotionModal = ({ modal, toggle, promotion, ...props }) => {
  const closeBtn = <button className="modal-payment-close" onClick={toggle}><i className="far fa-times-circle" /></button>;
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} contentClassName="modal-payment-content" {...props}>
        <ModalHeader toggle={toggle} close={closeBtn} className="modal-payment-header" />
        <ModalBody className="modal-payment-body">
          {promotion.img ? (
            <>
              <div>
                <img src={promotion.img} />
              </div>
              <div className="pt-3">
                <label>{promotion.name ?? ''}</label>
                <br />
                <small>{promotion.desc ?? ''}</small>
              </div>
            </>
          ) : (
            <div className="body-inner">
              <h6>{promotion.name ?? ''}</h6>
              <label>{promotion.desc ?? ''}</label>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default PromotionModal;
