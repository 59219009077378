import React, { Component, Fragment } from 'react';
import iCBDC from '../../assets/img/icons/cbdc-pilot.png';

class CBDCPaymentBox extends Component {
  render() {
    return (
      <Fragment>
        <hr className="custom-hr" />

        <div className="py-2">
          <h6>การชำระผ่าน <img src={iCBDC} height="32px" className="rounded mb-1 mr-2" /></h6>
        </div>
        <div className="billpayment-container">
          <h6 className="text-red font-weight-bold">กรุณาทำตามขั้นตอนที่แนะนำ (อยู่ระหว่างทำรายการ กรุณาอย่าปิด หรือรีเฟรชหน้าจอ)</h6>
          <ol>
            <Fragment>
              <li>
                <span className="text-red font-weight-bold">กรุณาชำระเงินภายใน 48 ชม. และไม่เกินเวลาที่กำหนด เพื่อไม่ให้คำสั่งซื้อของลูกค้า ถูกยกเลิก/หมดอายุ​</span>
              </li>
              <li>คลิกปุ่ม "กดสร้าง QR CBDC"</li>
              <li>คลิกปุ่ม "Save QR CBDC"</li>
              <li>เปิดแอปพลิเคชัน CBDC บนอุปกรณ์ของลูกค้า</li>
              <li>เลือกไปที่ปุ่ม "Scan QR" และกดที่รูปภาพ</li>
              <li>
                เลือก <span className="text-red">QR CBDC ที่ดาวน์โหลด</span> ไว้และทำการชำระเงิน
              </li>
            </Fragment>
          </ol>
        </div>
      </Fragment>
    );
  }
}

export default CBDCPaymentBox;
