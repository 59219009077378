import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Swal from 'sweetalert2';
import { config } from '../../config';

import CreditCard from './CreditCard';

class CreditCardBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: '',
    };
  }

  encryptCreditCard() {
    My2c2p.getEncrypted('2c2p-payment-form', (encryptedData, errCode, errDesc) => {
      if (errCode !== 0) alert(`${errDesc} (${errCode})`);
      else {
        let data = this.props.payment.getData();
        let cardHolderName = data.creditcard.name;
        let cardNumber = data.creditcard.number.replace(/[^0-9]/g, '');
        this.props.payment.chkBinCard(cardNumber);
        let payment = {
          uid: data.payment.uid,
          card_holder_name: cardHolderName,
          card_encrypted: encryptedData.encryptedCardInfo,
        };

        let url = `${config.npay.apiUrl}/charges/creditcard`;
        let method = 'post';
        let headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.props.token}`,
        };

        fetch(url, { method, headers, body: JSON.stringify(payment) })
          .then(response => response.json())
          .then(body => {
            if (body.statusCode === 500) {
              Swal.fire({
                title: 'Error!',
                text: body.message,
                type: 'error',
                confirmButtonText: 'OK',
              });
              throw new Error(body.message);
            }
            let payload = body.data.payload;
            this.setState({ payload }, () => {
              document.getElementById('ccpp-form').submit();
            });
          }).catch((error) => {
            console.log(error);
          });
      }
    });
  }

  // submitPayment() {
  //   document.getElementById('paymentRequestForm').submit();
  // }

  render() {
    return (
      <Fragment>
        <hr className="custom-hr" />
        <div className="py-2"><h6>ชำระเงินด้วย Credit Card</h6></div>
        <CreditCard formId="2c2p-payment-form" displayLogo />
        {/* <form id="ccpp-form" method="post" action={config.ccpp.securePayUrl}>
          <input type="hidden" name="paymentRequest" value={this.state.payload} />
        </form> */}
      </Fragment>
    );
  }
}

export default inject('payment')(observer(CreditCardBox));
