import moment from 'moment';
import BaseStore from './BaseStore';

import { config } from '../config';

export class DemoStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      ref_id: `order-${moment().format('YYMMDDHHmmssSSS')}`,
      description: 'Order ร้าน ABC Shop',
      customer_name: 'Peerapat',
      phone: '0123456789',
      email: 'test@email.com',
      postback_url: `${config.npay.webUrl}/demo/thankyou`,
      items: [
        {
          subtotal: 155.00,
          description: 'สินค้า 1',
        },
        {
          subtotal: 240.00,
          description: 'สินค้า 2',
        },
      ],
    });
  }

  requestPayment() {
    try {
      let url = `${config.npay.apiUrl}/payments/request`;
      let method = 'post';
      let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY2hhbm5lbF90b2tlbiIsImNoYW5uZWwiOnsidWlkIjoiQ0gxOTA1MjkxMzEwMjYyNDIiLCJuYW1lIjoiQUJDIFNob3AifSwiaWF0IjoxNTYxNTQyNjI2fQ.qykAORIjTjKt5e_iJZ9NNBirka1CayCe5EiZs31q2u8`,
      };

      let data = this.getData();
      data.ref_id = `order-${moment().format('YYMMDDHHmmssSSS')}`;

      console.log('request payment:');
      console.log(data);

      fetch(url, { method, headers, body: JSON.stringify(data) })
        .then(response => {
          console.log(response);
          return response.json();
        })
        .then(body => {
          console.log(body);
          window.location = body.data.payment_url;
        }).catch(err => {
          console.error(err);
        });
    } catch (err) {
      console.err(err);
    }
  }
}
