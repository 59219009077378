import React, { Fragment } from 'react';
import { Router, Route } from 'react-router-dom';

import { config } from '../config';
import { history } from '../utils/history';

import Home from '../pages/Home';
import PaymentPage from '../pages/payment/PaymentPage';
import PaymentResult from '../pages/PaymentResult';
import OTPPage from '../pages/otp/OTPPage';
import SellerRegisterPage from '../pages/seller/SellerRegisterPage';
import SetPassword from '../pages/user/SetPassword';
import BBLSlipPage from '../pages/payment/slip/BBLSlipPage';

class App extends React.Component {
  componentDidMount() {
    /// load my2c2p libr ///
    const script = document.createElement('script');
    script.src = config.ccpp.my2c2pUrl;
    document.body.appendChild(script);
  }

  render() {
    return (
      <Fragment>
        <Router history={history}>
          <Fragment>
            <Route exact path="/" component={Home} />
            <Route exact path="/payments/:uid" component={PaymentPage} />
            <Route exact path="/payments/:uid/slip/:bank" component={BBLSlipPage} />
            {/* <Route exact path="/payments/:uid/otp" component={OTPPage} /> */}
            <Route exact path="/otp" component={OTPPage} />
            <Route exact path="/thankyou" component={PaymentResult} />
            <Route exact path="/sellers/register" component={SellerRegisterPage} />
            <Route exact path="/user/SetPassword/:refid" component={SetPassword} />
          </Fragment>
        </Router>
      </Fragment>
    );
  }
}

export default App;
