import iTimerOff from './../assets/img/icons/timer-off-outline.svg';

export const T2P_ERROR_CODES = {
  Success: '0',
  InvalidSCGID: '1',
  InvalidData: '2',
  CardNotFound: '3',
  MemberProfileNotFound: '4',
  SendOTPfailed: '5',
  InvalidMobileNo: '6',
  WaitForSMS: '7',
  AlreadyRegistered: '8',
  RequestOTPFailFreez: '9',
  VerifyOTPFailIncorrect: '10',
  ChargeFail: '11',
  VerifyOTPFailExpire: '12',
  VerifyOTPFailFreez: '13',
  CardInactiveAndNotEnoughMoney: '14',
  CardInactiveAndEnoughMoney: '15',
  SystemError: '9999',
};

export const SYSTEM_ERROR_MSG = { type: 'warning', message: 'ระบบขัดข้อง โปรดลองใหม่อีกครั้ง​', buttonText: 'ลองใหม่อีกครั้ง', backToMerchant: true };
export const SYSTEM_ERROR_TEMP_MSG = { type: 'error', message: 'ระบบขัดข้อง โปรดติดต่อ Channel Support Team', buttonText: 'รับทราบ', backToMerchant: true };
export const T2P_ERROR_OTP_LIMIT_MSG = {
  type: 'warning',
  header: 'คุณได้กรอกรหัส OTP ผิดครบ 5 ครั้ง',
  message: 'กรุณารอ 15 นาที เพื่อกดขอ OTP ใหม่อีกครั้ง และโปรดกรอกรหัส OTP ให้ถูกต้องเพื่อทำรายการชำระเงิน',
  buttonText: 'รับทราบ',
  imageUrl: iTimerOff,
};

export const T2P_ERROR_ALERTS = {
  [T2P_ERROR_CODES.InvalidSCGID]: SYSTEM_ERROR_TEMP_MSG,
  [T2P_ERROR_CODES.InvalidData]: SYSTEM_ERROR_TEMP_MSG,
  [T2P_ERROR_CODES.CardNotFound]: SYSTEM_ERROR_TEMP_MSG,
  [T2P_ERROR_CODES.MemberProfileNotFound]: SYSTEM_ERROR_TEMP_MSG,
  [T2P_ERROR_CODES.RequestOTPFailFreez]: T2P_ERROR_OTP_LIMIT_MSG,
  [T2P_ERROR_CODES.VerifyOTPFailIncorrect]: { type: 'error', message: 'คุณกรอกรหัส OTP ไม่ถูกต้อง โปรดลองใหม่อีกครั้ง', buttonText: 'ลองอีกครั้ง' },
  [T2P_ERROR_CODES.ChargeFail]: {
    type: 'error',
    header: 'การชำระเงินไม่สำเร็จ',
    message: 'ยอดเงินคงเหลือของคุณไม่เพียงพอ โปรดเติมเงินและทำรายการชำระเงินใหม่อีกครั้ง',
    buttonText: 'ลองใหม่อีกครั้ง',
    backToMerchant: true,
  },
  [T2P_ERROR_CODES.VerifyOTPFailExpire]: {
    type: 'warning',
    header: 'รหัส OTP หมดอายุแล้ว',
    message: 'โปรดกดขอ OTP ใหม่อีกครั้ง และกรอกรหัส OTP ให้ถูกต้องเพื่อทำรายการชำระเงิน',
    buttonText: 'รับทราบ',
    imageUrl: iTimerOff,
  },
  [T2P_ERROR_CODES.VerifyOTPFailFreez]: T2P_ERROR_OTP_LIMIT_MSG,
  [T2P_ERROR_CODES.CardInactiveAndNotEnoughMoney]: {
    type: 'error',
    header: 'การชำระเงินไม่สำเร็จ',
    message: 'บัตรของคุณถูกระงับการใช้งานโปรดติดต่อเจ้าหน้าที่และยอดเงินคงเหลือของคุณไม่เพียงพอ โปรดเติมเงินก่อนทำรายการชำระเงินใหม่อีกครั้ง',
    buttonText: 'รับทราบ',
    backToMerchant: true,
  },
  [T2P_ERROR_CODES.CardInactiveAndEnoughMoney]: {
    type: 'error',
    header: 'การชำระเงินไม่สำเร็จ',
    message: 'บัตรของคุณถูกระงับการใช้งานโปรติดต่อเจ้าหน้าที่และทำรายการชำระเงินใหม่อีกครั้ง',
    buttonText: 'รับทราบ',
    backToMerchant: true,
  },
  [T2P_ERROR_CODES.SystemError]: SYSTEM_ERROR_MSG,
};

export const SYSTEM_ERROR_ALERTS = {
  B0005: {
    type: 'warning',
    message: 'ลิงค์การชำระเงินนี้หมดอายุแล้ว โปรดทำรายการใหม่อีกครั้ง',
    buttonText: 'รับทราบ',
    imageUrl: iTimerOff,
    backToMerchant: true,
  },
  A2000: SYSTEM_ERROR_MSG,
  B0009: SYSTEM_ERROR_MSG,
  B0016: {
    type: 'warning',
    header: 'คุณทำรายการไม่สำเร็จครบ 5 ครั้ง',
    message: 'กรุณารอ 5 นาที เพื่อทำรายการใหม่อีกครั้ง',
    buttonText: 'รับทราบ',
  },
};
