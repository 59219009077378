import React, { Component, Fragment } from 'react';
import iPromptpay from '../../assets/img/icons/promptpay-full-small.png';

class PromptPayPaymentBox extends Component {
  render() {
    return (
      <Fragment>
        <hr className="custom-hr" />

        <div className="py-2">
          <h6>การชำระผ่าน <img src={iPromptpay} height="32px" className="rounded mb-1 mr-2" /></h6>
        </div>
        <div className="billpayment-container">
          <h6 className="text-red font-weight-bold">กรุณาทำตามขั้นตอนที่แนะนำ (อยู่ระหว่างทำรายการ กรุณาอย่าปิด หรือรีเฟรชหน้าจอ)</h6>
          <ol>
            <Fragment>
              <li>
                <span className="text-red font-weight-bold">กรุณาชำระเงินภายใน 48 ชม. และไม่เกินเวลาที่กำหนดเพื่อไม่ให้คำสั่งซื้อของลูกค้า ถูกยกเลิก/หมดอายุ​</span>
              </li>
              <li>คลิกปุ่ม "กดสร้าง QR ชำระเงิน"</li>
              <li>คลิกปุ่ม "Save QR"</li>
              <li>เปิดแอปพลิเคชันธนาคารบนอุปกรณ์ของลูกค้า</li>
              <li>เลือกไปที่ปุ่ม "สแกน" หรือ "QR Code" และกดที่รูปภาพ</li>
              <li>
                เลือก <span className="text-red">QR ชำระเงินที่ ดาวน์โหลด</span> ไว้และทำการชำระเงิน
              </li>
            </Fragment>
          </ol>
        </div>
      </Fragment>
    );
  }
}

export default PromptPayPaymentBox;
