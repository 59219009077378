import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import PaymentItem from './PaymentItem';
// import BillPaymentButton from './BillPaymentButton';
import bay from '../../assets/img/logos/logo-Krungsri.png';
import bbl from '../../assets/img/logos/logo-BBL.png';
import kbank from '../../assets/img/logos/logo-Kbank.png';
import ktb from '../../assets/img/logos/logo-KTB.png';
import scb from '../../assets/img/logos/logo-SCB.png';
import bigc from '../../assets/img/bills/Image_1.png';
import tesco from '../../assets/img/bills/Image_2.png';
import cenpay from '../../assets/img/bills/Image_3.png';
import truemoney from '../../assets/img/bills/Image_4.png';
import mpay from '../../assets/img/bills/Image_5.png';

// const bay = 'https://azecomsa99.blob.core.windows.net/ecom/logo/banks/bay.png';
// const bbl = 'https://azecomsa99.blob.core.windows.net/ecom/logo/banks/bbl.png';
// const kbank = 'https://azecomsa99.blob.core.windows.net/ecom/logo/banks/kbank.png';
// const ktb = 'https://azecomsa99.blob.core.windows.net/ecom/logo/banks/ktb.png';
// const scb = 'https://azecomsa99.blob.core.windows.net/ecom/logo/banks/scb.png';
// const tbank = 'https://azecomsa99.blob.core.windows.net/ecom/logo/banks/tbank.png';
// const tmb = 'https://azecomsa99.blob.core.windows.net/ecom/logo/banks/tmb.png';
// const uob = 'https://azecomsa99.blob.core.windows.net/ecom/logo/banks/uob.png';

// const mpay = 'https://azecomsa99.blob.core.windows.net/ecom/logo/bills/mpay.png';
// const bigc = 'https://azecomsa99.blob.core.windows.net/ecom/logo/bills/bigc.jpg';
// const tesco = 'https://azecomsa99.blob.core.windows.net/ecom/logo/bills/tesco.jpg';
// const cenpay = 'https://azecomsa99.blob.core.windows.net/ecom/logo/bills/cenpay.jpg';
// const truemoney = 'https://azecomsa99.blob.core.windows.net/ecom/logo/bills/truemoney.jpg';

const bankChannels = [
  { agentCode: 'KBANK', logo: kbank, bgcolor: 'transparent', name: 'Kasikorn Bank' },
  { agentCode: 'SCB', logo: scb, bgcolor: 'transparent', name: 'Siam Commercial Bank' },
  { agentCode: 'BAY', logo: bay, bgcolor: 'transparent', name: 'Bank of Ayudhya (Krungsri)' },
  { agentCode: 'BBL', logo: bbl, bgcolor: 'transparent', name: 'Bangkok Bank' },
  { agentCode: 'KTB', logo: ktb, bgcolor: 'transparent', name: 'Krungthai Bank' },
  // { agentCode: 'TBANK', logo: tbank, bgcolor: '#fc4f1f', name: 'Thanachart Bank' },
  // { agentCode: 'TMB', logo: tmb, bgcolor: '#1279be', name: 'TMB Bank' },
  // { agentCode: 'UOB', logo: uob, bgcolor: '#0b3979', name: 'United Overseas Bank (Thai)' },
];

const counterChannels = [
  { agentCode: 'BIGC', logo: bigc, bgcolor: 'transparent', name: 'Big C Supercenter' },
  { agentCode: 'TESCO', logo: tesco, bgcolor: 'transparent', name: 'Tesco Lotus Counter Service' },
  { agentCode: 'TRUEMONEY', logo: truemoney, bgcolor: 'transparent', name: 'True Money Shop' },
  { agentCode: 'CENPAY', logo: cenpay, bgcolor: 'transparent', name: 'CenPay by Central' },
  { agentCode: 'MPAY', logo: mpay, bgcolor: 'transparent', name: 'mPay Station by AIS' },
];

class BillPaymentBox extends Component {
  // selectPayment(agentCode, channelCode) {
  //   this.props.payment.selectBillPaymentChannel(agentCode, channelCode);
  // }

  render() {
    // let data = this.props.payment.getData();
    let banks = bankChannels.map(channel => {
      return (
        <PaymentItem
          key={channel.agentCode}
          type="bank"
          // selected={data.billpayment.agentCode === channel.agentCode}
          logo={channel.logo}
          bgcolor={channel.bgcolor}
          name={channel.name}
          agentCode={channel.agentCode}
          // onClick={this.selectPayment.bind(this, channel.agentCode, 'BANKCOUNTER')}
        />
      );
    });

    let counters = counterChannels.map(channel => {
      return (
        <PaymentItem
          key={channel.agentCode}
          // selected={data.billpayment.agentCode === channel.agentCode}
          logo={channel.logo}
          bgcolor={channel.bgcolor}
          name={channel.name}
          agentCode={channel.agentCode}
          // onClick={this.selectPayment.bind(this, channel.agentCode, 'OVERTHECOUNTER')}
        />
      );
    });

    return (
      <Fragment>
        <hr className="custom-hr" />
        <div className="py-2">
          <h6>{this.props.type === 'mobile' ? 'การชำระบนมือถือ (Mobile Banking)' : 'การชำระผ่าน Counter Service'}</h6>
        </div>
        <div className="d-flex flex-wrap pb-3">
          {banks}
          {this.props.type === 'counter' && counters}
        </div>
        <div className="billpayment-container">
          <div>
            <h6>วิธีการชำระเงิน</h6>
            <ol>
              {this.props.type === 'mobile' ? (
                <Fragment>
                  <li>คลิก "พิมพ์ใบชำระเงิน"</li>
                  <li>สแกน QR Code หรือ Barcode เพื่อชำระเงินผ่าน Mobile Banking</li>
                </Fragment>
              ) : (
                <Fragment>
                  <li>คลิก "พิมพ์ใบชำระเงิน"</li>
                  <li>ปริ้นใบชำระเงินแล้วนำไปชำระเงินที่เคาน์เตอร์ธนาคาร หรือเคาน์เตอร์บริการที่รับชำระเงิน</li>
                </Fragment>
              )}
            </ol>
          </div>

          <div>
            <h6>เงื่อนไขการชำระเงิน</h6>
            <ul>
              {this.props.type === 'mobile' ? (
                <Fragment>
                  <li>
                    ชำระผ่านช่องทาง Mobile Banking สามารถชำระได้ โดยจำนวนเงินขึ้นอยู่กับลูกค้ากำหนด limit ของตัวเอง <b>ปกติ 50,000 บาท</b> โดยลูกค้าสามารถขยายวงเงินได้เอง
                  </li>
                  <li>
                    <b>SCB</b> ขยายวงเงินสูงสุดผ่าน SCB Application <b>2,000,000 บาท</b> ต่อวัน
                  </li>
                  <li>
                    <b>Kbank</b> ขยายวงเงินสูงสุดผ่าน K Plus <b>1,000,000 บาท</b> ต่อวัน
                  </li>
                  <li>
                    <b>KTB</b> ขยายวงเงินได้<b>ไม่จำกัด</b>
                  </li>
                  <li>
                    ธนาคารอื่นๆ ติดต่อกับทางธนาคารเพื่อขยายวงเงินได้สูงสุด <b>200,000 บาท</b> ต่อวัน
                  </li>
                </Fragment>
              ) : (
                <Fragment>
                  <li>ชำระด้วยเงินสดผ่านเคาน์เตอร์ธนาคาร</li>
                  <li>
                    ชำระด้วยเงินสดผ่านเคาน์เตอร์ให้บริการ สามารถชำระได้สูงสุด <b>ไม่เกิน 49,000 บาท</b> ต่อรายการ (ข้อกำหนดของธนาคารแห่งประเทศไทย)
                  </li>
                </Fragment>
              )}
              <li className="list-style-none">** ทางผู้รับชำระเงินอาจเรียกเก็บค่าธรรมเนียมเพิ่มเติมจากราคาดังกล่าว</li>
            </ul>
          </div>
        </div>
        {/* <BillPaymentButton /> */}
      </Fragment>
    );
  }
}

export default inject('payment')(observer(BillPaymentBox));
