import '@babel/polyfill';

import React from 'react';
import { render } from 'react-dom';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';

import stores from './src/stores';
import App from './src/components/App';

import 'react-responsive-select/dist/ReactResponsiveSelect.css';
import './src/assets/css/app.css';
import './src/assets/scss/custom.scss';

render((
  <Provider {...stores}>
    <BrowserRouter>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </BrowserRouter>
  </Provider>
), document.getElementById('root'));
