import { DemoStore } from './stores/DemoStore';
import { PaymentStore } from './stores/PaymentStore';
import { ChannelStore } from './stores/ChannelStore';
import { ScgWalletStore } from './stores/ScgWalletStore';
import { ChangFamilyWalletStore } from './stores/ChangFamilyWalletStore';
import { SellerRegisterStore } from './stores/SellerRegisterStore';
import { UserStore } from './stores/UserStore';
import { BankStore } from './stores/BankStore';

export default {
  demo: new DemoStore(),
  payment: new PaymentStore(),
  channel: new ChannelStore(),
  scgwallet: new ScgWalletStore(),
  changFamilyWallet: new ChangFamilyWalletStore(),
  sellerRegister: new SellerRegisterStore(),
  user: new UserStore(),
  bank: new BankStore(),
};
