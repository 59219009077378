import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import InputMask from 'react-text-mask';
import Image from '../forms/Image';

import '../../assets/scss/components/_creditcard.scss';
import iCVV from '../../assets/img/icons/cvv-28px.svg';

import jcb from '../../assets/img/credit-card/JCB.png'
import visa from '../../assets/img/credit-card/Visa.png'
import mastercard from '../../assets/img/credit-card/Mastercard.png'
import unionpay from '../../assets/img/credit-card/UnionPay.png'

class CreditCard extends Component {
  constructor(props) {
    super(props);
    this.inputCardNumber = React.createRef();
  }
  changeCardName(event) {
    this.props.payment.changeCardName(event.target.value);
  }

  changeCardNumber(event) {
    this.props.payment.changeCardNumber(event.target.value);
  }

  changeCardCVC(event) {
    this.props.payment.changeCardCVC(event.target.value);
  }

  changeCardExpiry(event) {
    this.props.payment.changeCardExpiry(event.target.value);
  }

  render() {
    let payment = this.props.payment.getData();
    let numberMask = [
      /\d/, /\d/, /\d/, /\d/, ' ',
      /\d/, /\d/, /\d/, /\d/, ' ',
      /\d/, /\d/, /\d/, /\d/, ' ',
      /\d/, /\d/, /\d/, /\d/,
    ];

    let expiryMask = [/\d/, /\d/, '/', /\d/, /\d/];
    let cardNumber = payment.creditcard.number.replace(/[^0-9]/g, '');
    let month = payment.creditcard.expiry.split('/')[0];
    let year = `20${payment.creditcard.expiry.split('/')[1]}`;
    let cvc = payment.creditcard.cvc;

    let logoStyle = { height: 40 };

    return (
      <Fragment>
        <Form id={this.props.formId}>
          <FormGroup>
            <Row>
              <Col xs="5">
                <Label>กรอกข้อมูลบัตรเครดิต</Label>
              </Col>
              <Col>
                {this.props.displayLogo && (
                  <div className="text-right" style={{ marginTop: -10 }}>
                    <Image src={visa} style={logoStyle} />
                    <Image src={mastercard} style={logoStyle} />
                    <Image src={jcb} style={logoStyle} />
                    <Image src={unionpay} style={logoStyle} />
                  </div>
                )}
              </Col>
            </Row>
            <Label>Card Holder Name</Label>
            <input className="form-payment"
              id="name-on-card"
              value={payment.creditcard.name}
              onChange={this.changeCardName.bind(this)}
            />
          </FormGroup>

          <FormGroup>
            <Label>Card Number</Label>
            <InputMask keepCharPositions
              id="card-number" type="tel"
              className="form-payment"
              mask={numberMask}
              value={payment.creditcard.number}
              onChange={this.changeCardNumber.bind(this)}
              ref={this.inputCardNumber}
            />
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="6" xs="6">
                <Label>Expiry date</Label>
                <InputMask id="expiry-date"
                  className="form-payment"
                  mask={expiryMask} keepCharPositions
                  value={payment.creditcard.expiry}
                  onChange={this.changeCardExpiry.bind(this)}
                />
              </Col>
              <Col md="6" xs="6">
                <Label>Security code</Label>
                <div className="input-icon">
                  <img src={iCVV} className="icon" />
                  <input id="security-code" type="password" className="form-payment" maxLength={3}
                    value={payment.creditcard.cvc}
                    onChange={this.changeCardCVC.bind(this)}
                  />
                </div>
              </Col>
            </Row>
          </FormGroup>

          <Input type="hidden" value={cardNumber} data-encrypt="cardnumber" />
          <Input type="hidden" value={month} data-encrypt="month" />
          <Input type="hidden" value={year} data-encrypt="year" />
          <Input type="hidden" value={cvc} data-encrypt="cvv" />
        </Form>
      </Fragment>
    );
  }
}

export default inject('payment')(observer(CreditCard));
