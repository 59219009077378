import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, FormGroup, Label, Input, Button, CustomInput } from 'reactstrap';

import Number from '../../components/forms/Number';
import FilePicker from '../../components/forms/FilePicker';

class SellerRegisterKYCCompany extends Component {
  changeCompanyFile(key, files) {
    if (files && files.length) {
      if (files.length > 1) files.shift();
      this.props.sellerRegister.changeCompanyFile(files[0], key);
    }
  }

  changeCompanyInput(key, event) {
    this.props.sellerRegister.changeCompanyInput(event.target.value, key);
  }

  changeKYCShareholder(i, key, event) {
    this.props.sellerRegister.changeKYCShareholder(event.target.value, key, i);
  }

  changeKYCShareholderType(selected, i, key) {
    this.props.sellerRegister.changeKYCShareholder(selected, key, i);
  }

  changeKYCShareholderCommittee(i, j, key, event) {
    this.props.sellerRegister.changeKYCShareholderCommittee(event.target.value, key, i, j);
  }

  addKYCShareholder() {
    this.props.sellerRegister.addKYCShareholder();
  }

  addKYCShareholderCommittee(i) {
    this.props.sellerRegister.addKYCShareholderCommittee(i);
  }

  removeKYCShareholder(i) {
    this.props.sellerRegister.removeKYCShareholder(i);
  }

  removeKYCShareholderCommittee(i, j) {
    this.props.sellerRegister.removeKYCShareholderCommittee(i, j);
  }

  render() {
    const { seller, kycCompanyUpload, taxId } = this.props.sellerRegister.getData();
    const colStyle = { size: 9, offset: 1 };

    return (
      <Fragment>
        <Row className="mt-4">
          <Col className="align-self-center">
            <b>ข้อมูลนิติบุคคล</b>
          </Col>
          <Col sm={{ size: 12 }}>
            <hr />
          </Col>
        </Row>
        <Row className="content my-3">
          <Col md={colStyle}>
            <FormGroup row className="align-items-center h-100 required">
              <Label sm="5" className="control-label">
                เลขประจำตัวผู้เสียภาษีอากร (นิติบุคคล)
              </Label>
              <Col sm="6">
                {/* <Number value={seller.kyc_company.company_taxid || ''} onChange={this.changeCompanyInput.bind(this, 'company_taxid')} maxLength={13} /> */}
                <Number value={taxId} maxLength={13} readOnly />
              </Col>
            </FormGroup>
          </Col>
          <Col md={colStyle} className="mt-3">
            <FormGroup row className="align-items-center h-100 required">
              <Label sm="5" className="control-label" for="companyIdentification">
                แบบรายการแสดงตน KYC
                <br />
                <small>(สำหรับนิติบุคคล)</small>
              </Label>
              <Col sm="6">
                <FilePicker
                  src={kycCompanyUpload.identification}
                  onDrop={this.changeCompanyFile.bind(this, 'identification')}
                  inputProps={{ multiple: false, accept: 'image/png, image/jpeg, .pdf' }}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={colStyle} className="mt-3">
            <FormGroup row className="align-items-center h-100 required">
              <Label sm="5" className="control-label" for="companyCertificate">
                หนังสือรับรองการจดทะเบียนบริษัท
                <br />
                <small>(ไม่เกิน 6 เดือน)</small>
              </Label>
              <Col sm="6">
                <FilePicker src={kycCompanyUpload.certificate} onDrop={this.changeCompanyFile.bind(this, 'certificate')} inputProps={{ multiple: false, accept: 'image/png, image/jpeg, .pdf' }} />
              </Col>
            </FormGroup>
          </Col>
          <Col md={colStyle} className="mt-3">
            <FormGroup row className="align-items-center h-100 required">
              <Label sm="5" className="control-label" for="companyVat20">
                ภพ. 20
                <br />
                <small>(ใบทะเบียนภาษีมูลค่าเพิ่ม)</small>
              </Label>
              <Col sm="6">
                <FilePicker src={kycCompanyUpload.vat20} onDrop={this.changeCompanyFile.bind(this, 'vat20')} inputProps={{ multiple: false, accept: 'image/png, image/jpeg, .pdf' }} />
              </Col>
            </FormGroup>
          </Col>
          <Col md={colStyle} className="mt-3">
            <FormGroup row className="align-items-center h-100 required">
              <Label sm="5" className="control-label" for="companyBoj5">
                บอจ. 5<br />
                <small>(บัญชีรายชื่อผู้ถือหุ้น)</small>
              </Label>
              <Col sm="6">
                <FilePicker src={kycCompanyUpload.boj5} onDrop={this.changeCompanyFile.bind(this, 'boj5')} inputProps={{ multiple: false, accept: 'image/png, image/jpeg, .pdf' }} />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="align-self-center">
            <b>ผู้ได้รับมอบอำนาจทอดสุดท้าย</b>
          </Col>
          <Col sm={{ size: 12 }}>
            <hr />
          </Col>
        </Row>
        <Row className="content my-3">
          <Col md={colStyle}>
            <FormGroup row className="align-items-center h-100 required">
              <Label sm="5" className="control-label">
                ชื่อ - นามสกุล
                <br />
                <small>(ภาษาไทย)</small>
              </Label>
              <Col sm="6">
                <Input value={seller.kyc_company.beneficial_owner_name || ''} onChange={this.changeCompanyInput.bind(this, 'beneficial_owner_name')} />
              </Col>
            </FormGroup>
          </Col>
          <Col md={colStyle} className="mt-3">
            <FormGroup row className="align-items-center h-100 required">
              <Label sm="5" className="control-label">
                เลขประจำตัวประชาชน
              </Label>
              <Col sm="6">
                <Number value={seller.kyc_company.beneficial_owner_personal_id || ''} onChange={this.changeCompanyInput.bind(this, 'beneficial_owner_personal_id')} maxLength={13} />
              </Col>
            </FormGroup>
          </Col>
          <Col md={colStyle} className="mt-3">
            <FormGroup row className="align-items-center h-100 required">
              <Label sm="5" className="control-label" for="companyBeneficialOwner">
                สำเนาบัตรประชาชน พร้อมลงลายมือชื่อ
                <br />
                <small>(ของผู้ได้รับมอบอำนาจทอดสุดท้าย)</small>
              </Label>
              <Col sm="6">
                <FilePicker
                  src={kycCompanyUpload.beneficial_owner}
                  onDrop={this.changeCompanyFile.bind(this, 'beneficial_owner')}
                  inputProps={{ multiple: false, accept: 'image/png, image/jpeg, .pdf' }}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={colStyle} className="mt-3">
            <FormGroup row className="align-items-center h-100">
              <Label sm="5" className="control-label" for="companyBeneficialOwner">
                เอกสารมอบอำนาจ (ถ้ามี)
                <br />
              </Label>
              <Col sm="6">
                <FilePicker
                  src={kycCompanyUpload.beneficial_owner_authorization}
                  onDrop={this.changeCompanyFile.bind(this, 'beneficial_owner_authorization')}
                  inputProps={{ multiple: false, accept: 'image/png, image/jpeg, .pdf' }}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="align-self-center">
            <b>ข้อมูลผู้ถือหุ้น (ถือหุ้น 25% ขึ้นไป)</b>
          </Col>
          <Col sm={{ size: 12 }}>
            <hr />
          </Col>
        </Row>
        <Row className="content my-3">
          <Col>
            {seller.kyc_company.kyc_shareholders.length > 0 &&
              seller.kyc_company.kyc_shareholders.map((el, i) => (
                <Fragment key={i}>
                  <Row className="mt-4 justify-content-md-center">
                    <Col md={8} className="align-self-center">
                      <b>{i + 1}. รายชื่อผู้ถือหุ้น</b>
                      <Button color="danger" size="sm" className="m-0 float-right" onClick={this.removeKYCShareholder.bind(this, i)}>
                        ลบผู้ถือหุ้น ({i + 1})
                      </Button>
                    </Col>
                    <Col sm={{ size: 12 }} md={8}>
                      <hr />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col md={4}>
                      <FormGroup className="required">
                        <Label className="mb-2 control-label">เลขประจำตัวประชาชน/นิติบุคคล</Label>
                        <Number value={el.tax_id || ''} onChange={this.changeKYCShareholder.bind(this, i, 'tax_id')} maxLength={13} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup className="required">
                        <Label className="mb-2 control-label">ประเภท</Label>
                        <div>
                          <CustomInput
                            type="radio"
                            id={`individualShareholderRadio${i}`}
                            name={`shareholderType${i}`}
                            label="บุคคลธรรมดา"
                            checked={el.type === 'individual'}
                            onChange={this.changeKYCShareholderType.bind(this, 'individual', i, 'type')}
                          />
                          <CustomInput
                            type="radio"
                            id={`corporateShareholderRadio${i}`}
                            name={`shareholderType${i}`}
                            label="นิติบุคคล"
                            checked={el.type === 'corporate'}
                            onChange={this.changeKYCShareholderType.bind(this, 'corporate', i, 'type')}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={1} />
                  </Row>
                  {el.type === 'individual' && (
                    <Row className="justify-content-md-center">
                      <Col md={4}>
                        <FormGroup>
                          <Label className="mb-2 control-label">ชื่อ (ถ้ามี)</Label>
                          <Input value={el.name || ''} onChange={this.changeKYCShareholder.bind(this, i, 'name')} />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label className="mb-2 control-label">นามสกุล</Label>
                          <Input value={el.lastname || ''} onChange={this.changeKYCShareholder.bind(this, i, 'lastname')} />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  {el.type === 'corporate' && (
                    <Row className="justify-content-md-center">
                      <Col md={{ size: 4 }}>
                        <FormGroup>
                          <Label className="mb-2 control-label">ชื่อบริษัท (ถ้ามี)</Label>
                          <Input value={el.name || ''} onChange={this.changeKYCShareholder.bind(this, i, 'name')} />
                        </FormGroup>
                      </Col>
                      <Col md={4} />
                    </Row>
                  )}
                  {el.type === 'corporate' && (
                    <Row>
                      <Col>
                        {el.kyc_shareholders_committee.length > 0 &&
                          el.kyc_shareholders_committee.map((elc, j) => (
                            <Fragment key={j}>
                              <Row className="mt-4 justify-content-md-center">
                                <Col md={8} className="align-self-center">
                                  <b>
                                    {i + 1}.{j + 1} รายชื่อคณะกรรมการ
                                  </b>
                                  <Button color="danger" size="sm" className="m-0 float-right" onClick={this.removeKYCShareholderCommittee.bind(this, i, j)}>
                                    ลบคณะกรรมการ ({i + 1}.{j + 1})
                                  </Button>
                                </Col>
                                <Col sm={{ size: 12 }} md={8}>
                                  <hr />
                                </Col>
                              </Row>
                              <Row className="mt-3 justify-content-md-center">
                                <Col md={4}>
                                  <FormGroup className="required">
                                    <Label className="mb-2 control-label">ชื่อ - นามสกุล</Label>
                                    <Input value={elc.personal_name || ''} onChange={this.changeKYCShareholderCommittee.bind(this, i, j, 'personal_name')} />
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label className="mb-2 control-label">เลขประจำตัวประชาชน (ถ้ามี)</Label>
                                    <Number value={elc.personal_id || ''} onChange={this.changeKYCShareholderCommittee.bind(this, i, j, 'personal_id')} maxLength={13} />
                                  </FormGroup>
                                </Col>
                                {/* <Col md={1}>
                            <FormGroup>
                              <Label className="mb-2">&nbsp;</Label>
                              <div className="clearfix" />
                              <Button block outline color="danger" className="m-0" onClick={this.removeKYCShareholderCommittee.bind(this, i, j)}>ลบ</Button>
                            </FormGroup>
                          </Col> */}
                              </Row>
                            </Fragment>
                          ))}

                        <Row>
                          <Col md={{ size: 3, offset: 2 }} className="mt-3 mb-2">
                            <Button outline size="md" color="default" onClick={this.addKYCShareholderCommittee.bind(this, i)}>
                              เพิ่มคณะกรรมการ
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Fragment>
              ))}

            <Row className="mt-4">
              <Col md={{ size: 3, offset: 2 }} className="mt-3">
                {seller.kyc_company.kyc_shareholders.length && (
                  <Button size="md" color="secondary" onClick={this.addKYCShareholder.bind(this)}>
                    เพิ่มข้อมูลผู้ถือหุ้น
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default inject('sellerRegister')(observer(SellerRegisterKYCCompany));
