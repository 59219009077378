import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import PaymentItem from './PaymentItem';
import LinePaymentButton from './LinePaymentButton';
import iLinePay from '../../assets/img/icons/linepay.png';

const rabbit = 'https://azecomsa99.blob.core.windows.net/fins/production/logo/rabbit.png';

const bankChannels = [
  // { agentCode: 'RAB', logo: rabbit, bgcolor: '#fec43b', name: 'Bank of Ayudhya (Krungsri)' },
  // { agentCode: 'BBL', logo: bbl, bgcolor: '#1e4598', name: 'Bangkok Bank' },
  // { agentCode: 'KBANK', logo: kbank, bgcolor: '#138f2d', name: 'Kasikorn Bank' },
  // { agentCode: 'KTB', logo: ktb, bgcolor: '#1ba5e1', name: 'Krungthai Bank' },
  // { agentCode: 'SCB', logo: scb, bgcolor: '#4e2e7f', name: 'Siam Commercial Bank' },
  // { agentCode: 'TBANK', logo: tbank, bgcolor: '#fc4f1f', name: 'Thanachart Bank' },
  // { agentCode: 'TMB', logo: tmb, bgcolor: '#1279be', name: 'TMB Bank' },
  // { agentCode: 'UOB', logo: uob, bgcolor: '#0b3979', name: 'United Overseas Bank (Thai)' },
];

const counterChannels = [
  // { agentCode: 'BIGC', logo: bigc, bgcolor: 'transparent', name: 'Big C Supercenter' },
  // { agentCode: 'TESCO', logo: tesco, bgcolor: 'transparent', name: 'Tesco Lotus Counter Service' },
  // { agentCode: 'CENPAY', logo: cenpay, bgcolor: 'transparent', name: 'CenPay by Central' },
  // { agentCode: 'TRUEMONEY', logo: truemoney, bgcolor: 'transparent', name: 'True Money Shop' },
  // { agentCode: 'MPAY', logo: mpay, bgcolor: 'transparent', name: 'mPay Station by AIS' },
];

class LinePaymentBox extends Component {
  // selectPayment(agentCode, channelCode) {
  //   this.props.payment.selectBillPaymentChannel(agentCode, channelCode);
  // }

  render() {
    let banks;
    // let data = this.props.payment.getData();
    if (bankChannels.length > 0) {
      banks = bankChannels.map(channel => {
        return (
          <PaymentItem key={channel.agentCode} type="bank"
            // selected={data.billpayment.agentCode === channel.agentCode}
            logo={channel.logo} bgcolor={channel.bgcolor}
            name={channel.name} agentCode={channel.agentCode}
          // onClick={this.selectPayment.bind(this, channel.agentCode, 'BANKCOUNTER')}
          />
        );
      });
    }

    let counters = counterChannels.map(channel => {
      return (
        <PaymentItem key={channel.agentCode}
          // selected={data.billpayment.agentCode === channel.agentCode}
          logo={channel.logo} bgcolor={channel.bgcolor}
          name={channel.name} agentCode={channel.agentCode}
        // onClick={this.selectPayment.bind(this, channel.agentCode, 'OVERTHECOUNTER')}
        />
      );
    });

    return (
      <Fragment>
        <hr className="custom-hr" />
        <div className="py-2"><h6>การชำระผ่าน <img src={iLinePay} height="20px" /></h6></div>
        <div >
          {banks}
          {this.props.type === 'counter' &&
            counters
          }
        </div>

        <div className="billpayment-container">
          <h6>วิธีการชำระเงิน</h6>
          <ol>
            <Fragment>
              <li>คลิก "ยืนยันชำระเงินด้วย LINE Pay"</li>
              <li>Login ด้วย Line Account</li>
              <li>ยืนยันการชำระเงินด้วย Application Line</li>
            </Fragment>
          </ol>
        </div>

        {/* <LinePaymentButton /> */}
      </Fragment>
    );
  }
}

export default inject('payment')(observer(LinePaymentBox));
