import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { Button } from 'reactstrap';
import { config } from '../../config';

class LinePaymentButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: '',
    };
  }

  submitPayment() {
    let data = this.props.payment.getData();
    let payment = {
      uid: data.payment.uid,
      agent_code: data.billpayment.agentCode,
      channel_code: data.billpayment.channelCode,
    };

    let url = `${config.npay.apiUrl}/charges/linepayment`;
    let method = 'post';
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    fetch(url, { method, headers, body: JSON.stringify(payment) })
      .then(response => response.json())
      .then(body => {
        if (body.statusCode === 500) {
          Swal.fire({
            title: 'Error!',
            text: body.message,
            type: 'error',
            confirmButtonText: 'OK',
          });
          throw new Error(body.message);
        }
        let payload = body.data.payload;
        this.setState({ payload }, () => {
          document.getElementById('ccpp-form').submit();
        });
      }).catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <Fragment>
        <Button block color="primary"
          style={{ backgroundColor: '#08bf5b', borderColor: '#08bf5b' }}
          onClick={this.submitPayment.bind(this)}>
          <i className="fab fa-line" /> ชำระเงินด้วย LINE Pay
        </Button>
        <form id="ccpp-form" method="post" action={config.ccpp.securePayUrl}>
          <input type="hidden" name="paymentRequest" value={this.state.payload} />
        </form>
      </Fragment>
    );
  }
}

export default inject('payment')(observer(LinePaymentButton));
