import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Card, CardHeader, CardBody, Row } from 'reactstrap';

import logo from '../../assets/img/nexterlogo.png';
import iQrCode2 from '../../assets/img/icons/qr_code_2-24px.svg';
import Currency from '../../components/payment/Currency';
import PaymentSubmit from './PaymentSubmit';
import NexterButton from '../../components/Button/NexterButton';
import Countdown from '../Countdown/Countdown';
import { validation } from '../../utils/validation';

// import bblBank from '../../../assets/img/logos/logo-BBL-full.png';

// const PaymentTable = ({ items }) => {
//   let total = items.reduce((total, item) => total + (+item.subtotal), 0);
//   let discount = items.reduce((discount, item) => discount + (+item.discount), 0);
//   let sum = +total - +discount || 0;
//   let rows = items.map((item, index) => {
//     return (
//       <tr key={index}>
//         <td>{item.description}</td>
//         <td className="text-right">
//           <Currency number={+item.subtotal} />
//         </td>
//       </tr>
//     );
//   });

//   let table = (
//     <Table bordered>
//       <thead>
//         <tr>
//           <th style={{ borderTop: 0 }}>รายการ</th>
//           <th className="text-right" style={{ borderTop: 0 }}>ยอดชำระ(บาท)</th>
//         </tr>
//       </thead>
//       <tbody>
//         {rows}
//       </tbody>
//     </Table>
//   );

//   return (
//     <div>
//       {table}
//       {/* <Row style={{ marginTop: 30 }}>
//         <Col md="6" xs="6">ยอดรวมทั้งหมด</Col>
//         <Col md="6" xs="6" className="text-right">
//           <Currency number={+total} />
//         </Col>
//       </Row>
//       <Row>
//         <Col md="6" xs="6">ส่วนลด</Col>
//         <Col md="6" xs="6" className="text-right">
//           <Currency number={+discount} />
//         </Col>
//       </Row> */}
//       <hr />
//       <Row style={{ fontWeight: 700, fontSize: '20px', marginBottom: 30 }}>
//         <Col md="6" xs="6">ยอดที่ต้องชำระ</Col>
//         <Col md="6" xs="6" className="text-right">
//           <Currency number={+sum} />
//         </Col>
//       </Row>
//     </div>
//   );
// };

class PaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
    };
  }
  toggleConfirm = () => {
    this.setState({ confirm: !this.state.confirm });
  };
  getSubmitBtnText = method => {
    switch (method) {
      case 'qr_box':
      case 'bill_payment_mobile':
      case 'bill_payment':
        return (
          <>
            <img src={iQrCode2} className="px-2" />
            <span>พิมพ์ใบชำระเงิน</span>
          </>
        );
      case 'promptpay':
        return (
          <>
            <img src={iQrCode2} className="px-2" />
            <span>กดสร้าง QR ชำระเงิน</span>
          </>
        );
      case 'cbdc':
        return (
          <>
            <img src={iQrCode2} className="px-2" />
            <span>กดสร้าง QR CBDC</span>
          </>
        );
      case 'line':
        return 'ยืนยันชำระเงินด้วย LINE Pay';
      case 'grabpay':
        return 'ยืนยันชำระเงินด้วย GrabPay';
      default:
        return 'ยืนยันชำระเงิน';
    }
  };

  getSubmitBtn = props => {
    let endTime = 0;
    if (props.method === 'scg_wallet') {
      let data = this.props.scgwallet.getData();
      endTime = data.disabledSendOtpButtonExpiredAt;
    } else if (props.method === 'chang_family_wallet') {
      let data = this.props.changFamilyWallet.getData();
      endTime = data.disabledSendOtpButtonExpiredAt;
    } else if (props.method === 'credit_card') {
      let data = this.props.payment.getData();
      endTime = data.disabledChargeCreditExpiredAt;
    } else if (props.method === 'installment') {
      let data = this.props.payment.getData();
      endTime = data.disabledChargeCreditIppExpiredAt;
    }
    if (['scg_wallet', 'chang_family_wallet', 'credit_card', 'installment'].includes(props.method)) {
      return (
        <Countdown
          endTime={endTime}
          render={data => {
            let text = props.text + (data.timeLeft > 0 ? ` (${data.formatTime(data.timeLeft)})` : '');
            let disabled = props.disabled || data.timeLeft > 0;
            return this.getDefaultSubmitBtn({ ...props, text, disabled });
          }}
        />
      );
    } else {
      return this.getDefaultSubmitBtn({ ...props });
    }
  };

  getDefaultSubmitBtn = props => {
    return (
      <PaymentSubmit alert={props.alert} disabled={props.disabled} sum={props.sum}>
        {props.text}
      </PaymentSubmit>
    );
  };

  removeMask = str => {
    return str.split(' ').join('').replaceAll('_', '');
  };

  formValidation = (data, scgwalletData, changFamilyWalletData) => {
    const method = data.payment.method;
    const mobileBankingOpn = data.mobile_banking_opn;
    const scgnumber = this.removeMask(scgwalletData.card_number);
    const changefamilynumber = this.removeMask(changFamilyWalletData.card_number);
    switch (method) {
      case 'credit_card':
      case 'installment':
        return this.creditCardformValidation(data.creditcard);
      case 'scg_wallet':
        return scgnumber?.length === 12;
      case 'chang_family_wallet':
        return changefamilynumber?.length === 12;
      case 'qr_box':
      case 'bill_payment_mobile':
      case 'bill_payment':
      case 'promptpay':
      case 'grabpay':
      case 'cbdc':
        return true;
      case 'line':
        return 'ยืนยันชำระเงินด้วย LINE Pay';
      case 'mobile_banking_opn':
        return !!mobileBankingOpn?.selectedMethodCode;
      default:
        return false;
    }
  };

  creditCardformValidation = data => {
    const cardname = data.name;
    const cardnumber = this.removeMask(data.number);
    const cardexpiry = this.removeMask(data.expiry);
    const cardcvc = data.cvc;
    if (!(cardname && cardnumber?.length === 16 && cardcvc?.length === 3)) {
      return false;
    }
    if (!(cardexpiry && validation.creditCardExpiryDate(cardexpiry))) {
      return false;
    }
    return true;
  };

  render() {
    let data = this.props.payment.getData();
    let scgwalletData = this.props.scgwallet.getData();
    let changFamilyWalletData = this.props.changFamilyWallet.getData();
    let payment = data.payment;
    let alert = this.props.alert;
    let submitBtnText = this.getSubmitBtnText(this.props.method);
    let submitBtnDisabled = !this.formValidation(data, scgwalletData, changFamilyWalletData);
    let expireDate = new Date(payment.expire_at).toLocaleString('th-TH');
    let total = data.payment.items.reduce((total, item) => total + +item.subtotal, 0);
    let discount = data.payment.items.reduce((discount, item) => discount + +item.discount, 0);
    let sum = +total - +discount || 0;
    let method = this.props.method;
    let submitBtn = this.getSubmitBtn({
      method,
      text: submitBtnText,
      sum,
      alert,
      disabled: submitBtnDisabled,
    });

    const buyerInfo = [
      { name: 'ชื่อผู้ซื้อ', desc: payment.customer_name },
      { name: 'เบอร์โทรศัพท์', desc: payment.phone },
      { name: 'อีเมล', desc: payment.email },
      { name: 'รายละเอียด', desc: payment.description },
      { name: 'Reference ID', desc: payment.ref_id },
    ];

    // return (
    //   <Card>
    //     <CardBody>
    //       <CardTitle style={{ textAlign: 'center', fontSize: '1.2rem', fontWeight: 700 }}>
    //         ข้อมูลการชำระเงิน
    //       </CardTitle>
    //       <hr />
    //       <Row>
    //         <Col md="6" xs="6">
    //           <CardSubtitle style={{ fontWeight: 700 }}>ผู้ชำระเงิน:</CardSubtitle>
    //           <CardText>{payment.customer_name}</CardText>

    //           <CardSubtitle style={{ fontWeight: 700 }}>เบอร์โทรศัพท์:</CardSubtitle>
    //           <CardText>{payment.phone}</CardText>
    //         </Col>
    //         <Col md="6" xs="6">
    //           <CardSubtitle style={{ fontWeight: 700 }}>อีเมล:</CardSubtitle>
    //           <CardText>{payment.email}</CardText>

    //           <CardSubtitle style={{ fontWeight: 700 }}>ข้อมูลอื่นๆ:</CardSubtitle>
    //           <CardText>{payment.description}</CardText>
    //         </Col>
    //       </Row>
    //     </CardBody>
    //     <CardBody style={{ paddingTop: 0 }}>
    //       <PaymentTable items={data.payment.items} />
    //       <div className="text-danger text-right">กรุณาชำระเงินภายในวันที่: {expireDate}</div>
    //     </CardBody>
    //   </Card>
    // );

    return (
      <>
        <Card>
          <CardHeader className="text-center gradient-header text-white py-3">
            <p className="font-big m-0">ข้อมูลการชำระเงิน</p>
          </CardHeader>
          {alert.status && (
            <div className={`alert-payment-status ${alert.status ?? ''} d-flex justify-content-center align-items-middle`}>
              <i className={`${alert.icon ?? ''} mx-3 icon`} />
              <span>{alert.message ?? ''}</span>
            </div>
          )}
          <CardBody className="payment-info-body">
            <Row>
              <Col className="mx-auto text-center">
                <img src={payment?.channel?.logo_url || logo} alt="" height="50" />
              </Col>
            </Row>
            <Row>
              <Col>
                <h6 className="text-center mt-3">{payment?.channel?.name || ''}</h6>
              </Col>
            </Row>
            <hr className="custom-hr" />
            {buyerInfo.map((info, i) => {
              return (
                <Row key={i}>
                  <Col xs="4">
                    <label className="font-medium">{info.name}</label>
                  </Col>
                  <Col xs="8">
                    <label className="font-16">{info.desc || '-'}</label>
                  </Col>
                </Row>
              );
            })}
            <hr className="custom-hr" />
            <Row>
              <Col>
                <div className="d-flex justify-content-between pb-1 pt-2 border-top-0 text-navy-blue font-medium">
                  <span>รายการสินค้า</span>
                  <span>ยอดชำระ (บาท)</span>
                </div>
              </Col>
            </Row>
            {data.payment.items
              .filter(item => item.description)
              .map((item, i) => {
                return (
                  <div key={i}>
                    <hr className="cart-line m-0" />
                    <Row key={i} className="d-flex justify-content-between cart">
                      <Col xs="7" className="text-left">
                        <span>{item.description}</span>
                      </Col>
                      <Col xs="5" className="text-right">
                        <span>
                          <Currency prefix="฿" number={+item.subtotal} />
                        </span>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            <hr className="custom-hr mt-0" />
            <Row className="d-flex justify-content-between font-16">
              <Col xs="7" className="text-left ">
                <label>ยอดรวมก่อนหักส่วนลด</label>
              </Col>
              <Col xs="5" className="text-right">
                <label>
                  <Currency prefix="฿" number={+total} />
                </label>
              </Col>
            </Row>
            {+discount > 0 && (
              <Row className="d-flex justify-content-between font16">
                <Col xs="7" className="text-left">
                  <label>ส่วนลดรวม</label>
                </Col>
                <Col xs="5" className="text-right">
                  <label>
                    <Currency prefix="฿" number={+discount} />
                  </label>
                </Col>
              </Row>
            )}
            <Row className="d-flex justify-content-between font-big">
              <Col xs="7" className="text-left">
                <p>ยอดที่ต้องชำระ (สุทธิ)</p>
              </Col>
              <Col xs="5" className="text-right">
                <h6 className="text-red">
                  <Currency prefix="฿" number={+sum} />
                </h6>
              </Col>
            </Row>
            <hr className="custom-hr mt-0" />
            {!['success', 'pending', 'settled'].find(s => s === alert.status) ? <p className="text-red font-small">กรุณาชำระเงินภายในวันที่ {expireDate}</p> : ''}

            {alert.status === 'expired' && data.payment?.channel?.website_url && (
              <section className="text-center">
                <a href={data.payment?.channel?.website_url}>
                  <NexterButton color="primary" size="sm">
                    กลับสู่ร้านค้า
                  </NexterButton>
                </a>
              </section>
            )}

            {/** 
            {!alert.status && (
              <>
                <Button
                  color="primary"
                  className="btn-block btn-sm mt-3"
                  // disabled={submitBtnDisabled}
                  onClick={() => this.toggleConfirm()}
                >
                  {submitBtnText}
                </Button>
              </>
            )}
            */}
            {submitBtn}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default inject('payment', 'scgwallet', 'changFamilyWallet')(observer(PaymentInfo));
