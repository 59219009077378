import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Row, Col, Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap';
import { KEY_PAYMENT_METHOD, OTP_SIZE } from './../../constants/paymentConstants';
import SendOTPButton from '../../components/payment/SendOTPButton';
import RequestOTPButton from '../../components/payment/RequestOTPButton';

import SFinPayLogoFull from '../../assets/img/sfinpay-logo-full.png';
import iOtp from '../../assets/img/icons/otp.svg';

class OTPPage extends Component {
  changeOTPNumber(event) {
    let value = event.target.value;
    value = value.replace(/\D/g, '').substring(0, OTP_SIZE);
    let paymentMethod = this.getPaymentMethod();
    if (paymentMethod === 'scg_wallet') {
      this.props.scgwallet.changeOTPNumber(value);
    } else if (paymentMethod === 'chang_family_wallet') {
      this.props.changFamilyWallet.changeOTPNumber(value);
    }
  }

  requestOTP() {
    let paymentMethod = this.getPaymentMethod();
    if (paymentMethod === 'scg_wallet') {
      this.props.scgwallet.requestOTP();
    } else if (paymentMethod === 'chang_family_wallet') {
      this.props.changFamilyWallet.requestOTP();
    }
  }

  submitOTP() {
    let paymentMethod = this.getPaymentMethod();
    if (paymentMethod === 'scg_wallet') {
      this.props.scgwallet.chargeScgWallet();
    } else if (paymentMethod === 'chang_family_wallet') {
      this.props.changFamilyWallet.chargeChangFamilyWallet();
    }
  }

  getPaymentMethod() {
    let paymentMethod = localStorage.getItem(KEY_PAYMENT_METHOD);
    return paymentMethod;
  }

  render() {
    let wallet;
    let walletData;
    let paymentMethod = this.getPaymentMethod();

    if (paymentMethod === 'scg_wallet') {
      wallet = this.props.scgwallet;
      walletData = wallet.getData();
    } else if (paymentMethod === 'chang_family_wallet') {
      wallet = this.props.changFamilyWallet;
      walletData = wallet.getData();
    } else {
      return null;
    }

    let phoneNumber = wallet.getPhoneNumber ? wallet.getPhoneNumber() : null;
    let expiryDisabledChargeTime = wallet.getExpiryDisabledChargeTime();
    let expiryDisabledRequestOtpTime = wallet.getExpiryDisabledRequestOtpTime();
    let expiryDisabledTime = expiryDisabledChargeTime > expiryDisabledRequestOtpTime ? expiryDisabledChargeTime : expiryDisabledRequestOtpTime;

    return (
      <Container className="otp-container">
        <Row className="main-row justify-content-center">
          <Col className="p-0" sm={{ size: 12 }} md={{ size: 10 }} lg={{ size: 6 }}>
            <Card>
              <CardHeader className="d-flex align-items-center justify-content-between gradient-header text-white py-3">
                <img className="logo" src={SFinPayLogoFull} height={46} />
                <div className="font-big mb-0">ยืนยันรหัส OTP</div>
              </CardHeader>
              <CardBody className="px-4 pb-4 px-sm-5 pb-sm-5">
                <div className="icon-container text-center">
                  <img className="otp-img mb-4" src={iOtp} height={80} />
                </div>
                <h5 className="title font-big text-center text-green">กรุณายืนยันรหัส OTP</h5>
                <FormGroup>
                  <Label className="description font-medium text-center w-100 mb-0">
                    รหัสจะถูกส่งไปยังหมายเลขโทรศัพท์ {phoneNumber && `(${phoneNumber})`}
                    <br /> ที่ท่านได้ลงทะเบียนไว้
                  </Label>
                  <div className="mt-4 px-3">
                    <input autoFocus className="form-payment form-payment-otp" value={walletData.otp_number} disabled={walletData.disabledSendOtpButton} onChange={this.changeOTPNumber.bind(this)} />
                  </div>
                </FormGroup>
                <Row className="justify-content-center mt-4 mt-sm-5">
                  <Col xs={{ size: 8 }}>
                    <SendOTPButton
                      loading={walletData.loadingChargeWallet}
                      otp={walletData.otp_number}
                      expiryTime={expiryDisabledChargeTime}
                      onClick={this.submitOTP.bind(this)}
                      onTimeStart={() => wallet.toggleSendOtpButton(true)}
                      onTimeOut={() => wallet.toggleSendOtpButton(false)}
                    />
                  </Col>
                </Row>
                <div className="d-flex flex-column flex-lg-row justify-content-between mt-5 pt-3">
                  <div className="font-14">
                    กรณียังไม่ได้รับ OTP ให้กด "Request OTP"
                    <br /> หลังครบเวลาที่กำหนด เพื่อขอ OTP ใหม่อีกครั้ง
                  </div>
                  <div className="pt-3 pt-lg-0">
                    <RequestOTPButton loading={walletData.loadingRequestOtp} expiryTime={expiryDisabledTime} onClick={this.requestOTP.bind(this)} />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default inject('scgwallet', 'changFamilyWallet')(observer(OTPPage));
