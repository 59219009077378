import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';

export class ChannelStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      loading: false,
      channel: {},
    });
  }

  async fetchChannelSettings(uid) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/channels/settings/${uid}`;

      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.channel = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
}
