import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { config } from '../../config';
import { history } from '../../utils/history';
import { http } from '../../utils/http';
import CreditCardModal from './CreditCardModal';

class PaymentSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: '',
      confirm: false,
      loadingButton: false,
    };
  }

  toggleConfirm = () => {
    this.setState({ ...this.state, confirm: !this.state.confirm });
  };

  clickConfirm = async method => {
    let uid = localStorage.getItem('payment_uid');
    localStorage.setItem('payment_method', method);
    switch (method) {
      case 'credit_card':
        this.state.confirm ? this.verifyCardBin(method) : this.toggleConfirm();
        break;
      case 'installment':
        this.state.confirm ? this.verifyCardBin(method) : this.toggleConfirm();
        break;
      case 'qr_box':
        await this.bblMailNoti();
        if (uid) {
          this.changeBblPaymentPending(uid);
        }
        break;
      case 'bill_payment_mobile':
        this.submitPayment('bill_payment_mobile');
        break;
      case 'bill_payment':
        this.submitPayment('bill_payment');
        break;
      case 'scg_wallet':
        this.requestOTP('scg_wallet');
        break;
      case 'chang_family_wallet':
        this.requestOTP('chang_family_wallet');
        break;
      case 'line':
        this.submitLinePayment();
        break;
      case 'promptpay':
        this.submitPromptPayPayment();
        break;
      case 'grabpay':
        this.submitGrabPayPayment();
        break;
      case 'cbdc':
        this.submitCBDCPayment();
      case 'mobile_banking_opn':
        this.submitMobileBankingOpnPayment();
        break;
    }
  };
  async bblMailNoti() {
    try {
      let data = this.props.payment.getData();
      let body = { uid: data.payment.uid };
      let url = `${config.npay.apiUrl}/charges/bbl/noti`;
      await http.post(url, { body });
    } catch (err) {
      console.error(err);
    }
  }
  encryptCreditCard() {
    My2c2p.getEncrypted('2c2p-payment-form', async (encryptedData, errCode, errDesc) => {
      if (errCode !== 0) alert(`${errDesc} (${errCode})`);
      else {
        let data = this.props.payment.getData();
        let cardHolderName = data.creditcard.name;
        let cardNumber = data.creditcard.number.replace(/[^0-9]/g, '');
        cardNumber = cardNumber.substr(0, 8);

        let payment = {
          uid: data.payment.uid,
          card_holder_name: cardHolderName,
          card_encrypted: encryptedData.encryptedCardInfo,
        };

        let url = `${config.npay.apiUrl}/charges/creditcard`;
        let method = 'post';
        let headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.token}`,
        };

        fetch(url, { method, headers, body: JSON.stringify(payment) })
          .then(response => response.json())
          .then(body => {
            if ([500, 429].includes(body.statusCode)) {
              if (body.statusCode === 429) {
                this.props.payment.changeExpiryDisabledChargeCreditTime(body.expired_at);
                this.toggleConfirm();
              }
              Swal.fire({
                title: 'Error!',
                text: body.message,
                type: 'error',
                confirmButtonText: 'OK',
              });
              throw new Error(body.message);
            }
            let payload = body.data.payload;
            this.setState({ payload }, () => {
              document.getElementById('ccpp-form').submit();
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  }
  changeBblPaymentPending(uid) {
    // let data = this.props.payment.getData();
    let payment = {
      uid: uid,
    };
    let url = `${config.npay.apiUrl}/payments/bbl/pending`;
    let method = 'post';
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    fetch(url, { method, headers, body: JSON.stringify(payment) })
      .then(response => response.json())
      .then(body => {
        if (body.data.code === 'success') {
          history.push(`/payments/${uid}/slip/bbl`);
        } else {
          Swal.fire({
            title: 'Error!',
            text: body.data.message,
            type: 'error',
            confirmButtonText: 'OK',
          });
          throw new Error(body.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  submitPayment(paymentChannel) {
    let data = this.props.payment.getData();
    let payment = {
      uid: data.payment.uid,
      agent_code: data.billpayment.agentCode,
      channel_code: data.billpayment.channelCode,
      payment_channel: paymentChannel,
    };

    let url = `${config.npay.apiUrl}/charges/billpayment`;
    let method = 'post';
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    fetch(url, { method, headers, body: JSON.stringify(payment) })
      .then(response => response.json())
      .then(body => {
        if (body.statusCode === 500) {
          Swal.fire({
            title: 'Error!',
            text: body.message,
            type: 'error',
            confirmButtonText: 'OK',
          });
          throw new Error(body.message);
        }
        let payload = body.data.payload;
        this.setState({ payload }, () => {
          document.getElementById('ccpp-form').submit();
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  requestOTP(type) {
    if (type === 'scg_wallet') {
      this.props.scgwallet.requestOTP({ redirect: true });
    } else if (type === 'chang_family_wallet') {
      this.props.changFamilyWallet.requestOTP({ redirect: true });
    }
  }

  submitLinePayment() {
    let data = this.props.payment.getData();
    let payment = {
      uid: data.payment.uid,
      agent_code: data.billpayment.agentCode,
      channel_code: data.billpayment.channelCode,
    };

    let url = `${config.npay.apiUrl}/charges/linepayment`;
    let method = 'post';
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    fetch(url, { method, headers, body: JSON.stringify(payment) })
      .then(response => response.json())
      .then(body => {
        if (body.statusCode === 500) {
          Swal.fire({
            title: 'Error!',
            text: body.message,
            type: 'error',
            confirmButtonText: 'OK',
          });
          throw new Error(body.message);
        }
        let payload = body.data.payload;
        this.setState({ payload }, () => {
          document.getElementById('ccpp-form').submit();
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  async verifyCardBin(type) {
    let payment = this.props.payment.getData();
    let cardNumber = payment.creditcard.number.replace(/[^0-9]/g, '');

    if (cardNumber.length !== 16) {
      Swal.fire({
        type: 'warning',
        title: 'หมายเลขบัตรไม่ถูกต้อง',
      });
      return false;
    }

    let passed = await this.props.payment.chkBinCard(cardNumber, {
      onLocked: () => this.toggleConfirm(),
    });
    if (passed) {
      if (type == 'credit_card') {
        this.encryptCreditCard(this);
      } else if (type == 'installment') {
        this.encryptInstallmentCreditCard(this);
      }
    }
  }

  encryptInstallmentCreditCard() {
    My2c2p.getEncrypted('2c2p-payment-form', (encryptedData, errCode, errDesc) => {
      if (errCode !== 0) alert(`${errDesc} (${errCode})`);
      else {
        let payment = this.props.payment.getData();
        let cardHolderName = payment.creditcard.name;

        let data = {
          uid: payment.payment.uid,
          card_holder_name: cardHolderName,
          installment_period: payment.installment.period,
          card_encrypted: encryptedData.encryptedCardInfo,
        };

        let url = `${config.npay.apiUrl}/charges/installment`;
        let method = 'post';
        let headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.token}`,
        };

        fetch(url, { method, headers, body: JSON.stringify(data) })
          .then(response => response.json())
          .then(body => {
            if ([500, 429].includes(body.statusCode)) {
              if (body.statusCode === 429) {
                this.props.payment.changeExpiryDisabledChargeCreditIppTime(body.expired_at);
                this.toggleConfirm();
              }
              Swal.fire({
                title: 'Error!',
                text: body.message,
                type: 'error',
                confirmButtonText: 'OK',
              });
              throw new Error(body.message);
            }
            let payload = body.data.payload;
            this.setState({ payload }, () => {
              document.getElementById('ccpp-form').submit();
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  }

  async submitPromptPayPayment() {
    try {
      this.setState({ loadingButton: true });
      let data = this.props.payment.getData();
      let payment = {
        uid: data.payment.uid,
      };

      let url = `${config.npay.apiUrl}/charges/promptpay`;
      let response = await http.post(url, { body: payment });
      let body = await response.json();
      if (response.status === 200) {
        if (body.data?.web_payment_url) {
          window.location.href = body.data.web_payment_url;
        }
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error!',
          text: body.message,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loadingButton: false });
    }
  }

  async submitGrabPayPayment() {
    try {
      this.setState({ loadingButton: true });
      let data = this.props.payment.getData();
      let payment = {
        uid: data.payment.uid,
      };

      let url = `${config.npay.apiUrl}/charges/grabpay`;
      let response = await http.post(url, { body: payment });
      let body = await response.json();
      if (response.status === 200) {
        if (body.data?.web_payment_url) {
          window.location.href = body.data.web_payment_url;
        }
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error!',
          text: body.message,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loadingButton: false });
    }
  }

  async submitCBDCPayment() {
    try {
      this.setState({ loadingButton: true });
      let data = this.props.payment.getData();
      let payment = {
        uid: data.payment.uid,
      };

      let url = `${config.npay.apiUrl}/charges/cbdc`;
      let response = await http.post(url, { body: payment });
      let body = await response.json();
      if (response.status === 200) {
        if (body.data?.web_payment_url) {
          window.location.href = body.data.web_payment_url;
        }
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error!',
          text: body.message,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loadingButton: false });
    }
  }

  async submitMobileBankingOpnPayment() {
    try {
      this.setState({ loadingButton: true });
      const data = this.props.payment.getData();
      const sourceType = data?.mobile_banking_opn?.selectedMethodCode;
      if (!sourceType) {
        return;
      }
      let payment = {
        uid: data.payment.uid,
        source_type: `mobile_banking_${sourceType}`,
      };

      let url = `${config.npay.apiUrl}/charges/opn`;
      let response = await http.post(url, { body: payment });
      let body = await response.json();
      if (response.status === 200) {
        if (body.data?.web_payment_url) {
          window.location.href = body.data.web_payment_url;
        }
      } else {
        Swal.fire({
          type: 'error',
          title: body.title || 'Error!',
          html: body.message,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loadingButton: false });
    }
  }

  render() {
    let alert = this.props.alert;
    let loading = this.props.scgwallet?.loadingRequestOtp || this.props.changFamilyWallet?.loadingRequestOtp || this.state.loadingButton || false;
    let submitBtnDisabled = this.props.disabled || loading;
    let sum = this.props.sum;
    let data = this.props.payment.getData();
    // let scgwalletData = this.props.scgwallet.getData();
    let payment = data.payment;

    return (
      <>
        {!alert.status && (
          <>
            <Button color="primary" className="btn-block btn-md mt-3" disabled={submitBtnDisabled} onClick={() => this.clickConfirm(payment.method)}>
              {loading ? 'กรุณารอสักครู่...' : this.props.children}
            </Button>
          </>
        )}
        <CreditCardModal
          installment={data.installment}
          method={payment.method}
          modal={this.state.confirm}
          toggle={this.toggleConfirm.bind(this)}
          confirm={this.clickConfirm.bind(this, payment.method)}
          cardnumber={data.creditcard.number}
          sum={sum}
        />
        <form id="ccpp-form" method="post" action={config.ccpp.securePayUrl}>
          <input type="hidden" name="paymentRequest" value={this.state.payload} />
        </form>
      </>
    );
  }
}

PaymentSubmit.defaultProps = {
  alert: {},
  disabled: false,
  sum: 0,
};

PaymentSubmit.propType = {
  alert: PropTypes.object,
  disabled: PropTypes.boolean,
  sum: PropTypes.number,
};

export default inject('payment', 'scgwallet', 'changFamilyWallet')(observer(PaymentSubmit));
