import '../../assets/css/payment.css';
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import NexterButton from './../Button/NexterButton';

const PaymentResultCard = ({ status, icon, image, header, message, subMessage, shopUrl }) => {
  return (
    <Card className="payment-result-card p-3">
      <CardBody className="payment-result-wrapper">
        <div className={`header ${status || ''}`}>
          {!!image ? <img className="image" src={image} /> : <i className={`${icon} icon`} />}
          <div className="mt-4">
            <h5>
              <b className="title">{header}</b>
            </h5>
          </div>
        </div>
        <div className="description mt-3">{message}</div>
        {!!shopUrl && (
          <div className="mt-4">
            <a href={shopUrl}>
              <NexterButton size="md">กลับสู่ร้านค้า</NexterButton>
            </a>
          </div>
        )}
        {subMessage ? (
          <div className="my-3" style={{ whiteSpace: 'pre-line' }}>
            <small>{subMessage}</small>
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default PaymentResultCard;
