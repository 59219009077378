import React from 'react';
// import { Media } from 'reactstrap';

const PaymentItem = ({ type, logo, bgcolor, name, agentCode, selected, onClick }) => {
  // const mediaStyle = {
  //   padding: 6,
  //   border: selected ? '2px solid #007bff' : '2px solid transparent',
  //   borderRadius: 4,
  //   cursor: 'pointer',
  // };

  const nologo = 'https://yashiindia.com/wp-content/uploads/2017/07/no-logo-available.gif';

  return (
    // <Media style={mediaStyle} onClick={onClick}>
    //   <Media left href="#">
    //     <Media object alt="logo" width={52} src={logo || nologo}
    //       style={{
    //         marginRight: 8,
    //         borderRadius: 4,
    //         padding: (type === 'bank') ? 6 : 0,
    //         backgroundColor: bgcolor,
    //       }} />
    //   </Media>
    //   <Media body>
    //     <Media heading style={{ fontSize: '1.2rem' }}>{agentCode}</Media>
    //     { name }
    //   </Media>
    // </Media>
    <div className="payment-logo-wrapper rounded mb-1 mr-1"
      style={{
        padding: (type === 'bank') ? 3 : 0,
        backgroundColor: bgcolor,
      }}>
      <img
        src={logo || nologo}
        alt={name}
        className={`img-fluid ${type === 'bank' ? '' : 'rounded'}`}
      />
    </div>
  );
};

export default PaymentItem;
