export const KEY_PAYMENT_METHOD = 'payment_method';
export const KEY_PAYMENT_UID = 'payment_uid';
export const KEY_CARD_NUMBER_SCG = 'card_number';
export const KEY_CARD_NUMBER_CHANG = 'card_number_chang_family_wallet';
export const KEY_PHONE_NUMBER_CHANG = 'phone_number_chang_family_wallet';
export const KEY_EXPIRY_DISABLE_OTP_CHANG = 'expiry_otp_disabled_request_chang_family_wallet';
export const KEY_EXPIRY_DISABLE_OTP_SCG = 'expiry_otp_disabled_request_scg_wallet';
export const KEY_EXPIRY_DISABLE_CHARGE_CHANG = 'expiry_disabled_charge_chang_family_wallet';
export const KEY_EXPIRY_DISABLE_CHARGE_SCG = 'expiry_disabled_charge_scg_wallet';
export const KEY_EXPIRY_DISABLE_CHARGE_CREDIT = 'expiry_disabled_charge_credit';
export const KEY_EXPIRY_DISABLE_CHARGE_CREDIT_IPP = 'expiry_disabled_charge_credit_ipp';

export const OTP_SIZE = 6;
export const OTP_FREEZE_TIME = 60000;
export const CHARGE_FREEZE_TIME = 900000;