import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import logoKBANK from '../../assets/img/logos/logo-Kbank.png';
import logoSCB from '../../assets/img/logos/logo-SCB.png';
import logoBAY from '../../assets/img/logos/logo-Krungsri.png';
import logoBBL from '../../assets/img/logos/logo-BBL.png';
import logoKTB from '../../assets/img/logos/logo-KTB.png';

const LOGOS = {
  kbank: logoKBANK,
  scb: logoSCB,
  bay: logoBAY,
  bbl: logoBBL,
  ktb: logoKTB,
};

class MobileBankingOpnPaymentBox extends Component {
  changePaymentMethod(code) {
    this.props.payment.changeMobileBankingOpnMethod(code);
  }

  render() {
    const { payment, mobile_banking_opn } = this.props.payment.getData();
    const banks = payment.channel?.mobile_banking_opn_method || [];

    return (
      <Fragment>
        <hr className="custom-hr" />
        <div className="py-2">
          <h6>ชำระด้วย Mobile Banking (App Switch)</h6>
          <div>เลือกธนาคารในการชำระเงิน</div>
        </div>

        {banks.map((item, i) => {
          const isChecked = item.code === mobile_banking_opn.selectedMethodCode;
          return (
            <div key={item.code} className="pt-1">
              <button className={classNames('btn-payment', { active: isChecked })} onClick={this.changePaymentMethod.bind(this, item.code)}>
                <div className="row">
                  <div className="col-3 text-center">
                    <img className="rounded" src={LOGOS[item.code]} width={24} height={24} />
                  </div>
                  <div className="col-7 text-left text-black font-medium">
                    <span>{item?.code?.toUpperCase()}</span>
                  </div>
                  <div className="col-2 text-right font-medium">
                    <i className={classNames(isChecked ? 'fas fa-check-circle' : 'far fa-circle', 'option-check-icon', { checked: isChecked })} />
                  </div>
                </div>
              </button>
              {isChecked && (
                <div className="my-2">
                  <DetailBox expiryTime={item.expiry_time} />
                </div>
              )}
            </div>
          );
        })}
      </Fragment>
    );
  }
}

const DetailBox = ({ expiryTime }) => {
  return (
    <div className="billpayment-container">
      <ol>
        <li>
          <span className="text-red font-weight-bold">กรุณาชําระเงินภายใน {expiryTime} นาที​</span> เพื่อไม่ให้คําสั่งซื้อของลูกค้า ถูกยกเลิก/หมดอายุ
        </li>
        <li>
          เมื่อกด <span className="font-weight-bold">"ยืนยันชําระเงิน"</span> ด้วย Mobile banking ระบบจะทําการ redirect ไปที่แอปพลิเคชันของธนาคารนั้นๆ
        </li>
        <li>หากลูกค้ามียอดเงินไม่พอชําระ หรือไม่มีแอปพลิเคชันของธนาคารที่เลือกชําระเงิน จะทําให้การชําระเงินถูกยกเลิก/หมดอายุ</li>
        <li>กรณีที่ลูกค้ากดเลือกธนาคารที่ต้องการชําระเงิน เมื่อไปที่หน้าชําระเงินบนแอปธนาคาร ลูกค้าจะไม่สามารถเปลี่ยนมาใช้ธนาคารอื่นได้จนกว่าการชําระเงินจะหมดอายุ</li>
      </ol>
    </div>
  );
};

export default inject('payment')(observer(MobileBankingOpnPaymentBox));
