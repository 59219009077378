/* global FormData */
import BaseStore from './BaseStore';
import _ from 'lodash';
import Swal from 'sweetalert2';

import { config } from '../config';
import { http } from '../utils/http';
import { unique } from '../utils/unique';
// import { validation } from '../utils/validation';
import { file as fileUtil } from '../utils/file';

export class SellerRegisterStore extends BaseStore {
  constructor() {
    super();
    this.observable(this.getInitialData());
  }

  getInitialData() {
    return {
      loading: false,
      seller: {
        uid: unique.generateSellerUid(),
        name: '',
        // email: '',
        type: '', // 'partner'
        kyc_type: 'personal',
        service_charge_rate: 3,
        service_charge_absorb: true,
        phone_number: '',
        sellers_account: {},
        kyc_company: {
          company_taxid: '',
          beneficial_owner_name: '',
          beneficial_owner_personal_id: '',
          kyc_shareholders: [
            {
              tax_id: '',
              name: '',
              lastname: '',
              type: '',
              kyc_shareholders_committee: [],
            },
          ],
        },
        kyc_personal: {
          name: '',
          personal_id: '',
          file_kyc_personal: '',
          file_idcard: '',
        },
      },
      kycCompanyUpload: {
        identification: null,
        certificate: null,
        vat20: null,
        boj5: null,
        beneficial_owner: null,
        beneficial_owner_authorization: null,
      },
      kycPersonalUpload: {
        kyc_personal: '',
        idcard: '',
      },
      taxId: '',
      isRegistered: null,
      channel: {},
      channelSeller: {},
      sellerKyc: {},
    };
  }

  changeSellerValue(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.seller[name] = value;
  }

  changeTaxId(taxId) {
    this.taxId = taxId;
  }

  changeIsRegistered(val) {
    this.isRegistered = val;
  }

  changeSellerKYCType(type) {
    this.seller.kyc_type = type;
  }

  changeSellerName(name) {
    this.seller.name = name;
  }

  changeSellerPhoneNumber(phoneNumber) {
    this.seller.phone_number = phoneNumber;
  }

  // changeSellerType(type) {
  //   this.seller.type = type;
  // }

  // changeSellerEmail(email) {
  //   this.seller.email = email;
  // }

  changeBank(code, name) {
    this.seller.sellers_account.bank_code = code;
    this.seller.sellers_account.bank_name = name;
  }

  changeBankAccountName(name) {
    this.seller.sellers_account.bank_account_name = name;
  }

  changeBankAccountNumber(no) {
    this.seller.sellers_account.bank_account_no = no;
  }

  changeDealerCode(code) {
    this.seller.sellers_account.dealer_code = code;
  }

  async changeBankAccountImage(file) {
    let compressed = await fileUtil.compressImage(file);
    this.seller.sellers_account.bank_account_image = compressed;
    this.seller.sellers_account.bank_account_image_preview = URL.createObjectURL(compressed);
  }

  changeCompanyInput(val, key) {
    this.seller.kyc_company[key] = val;
  }

  changeCompanyFile(file, key) {
    this.kycCompanyUpload[key] = file;
  }

  changeKYCPersonalInput(val, key) {
    this.seller.kyc_personal[key] = val;
  }

  changeKYCPersonalFile(file, key) {
    this.kycPersonalUpload[key] = file;
  }

  addKYCShareholder() {
    this.seller.kyc_company.kyc_shareholders.push({
      tax_id: '',
      name: '',
      lastname: '',
      type: '',
      kyc_shareholders_committee: [],
    });
  }

  addKYCShareholderCommittee(i) {
    this.seller.kyc_company.kyc_shareholders[i].kyc_shareholders_committee.push({
      personal_name: '',
      personal_id: '',
    });
  }

  removeKYCShareholder(i) {
    if (this.seller.kyc_company.kyc_shareholders.length > 1) {
      this.seller.kyc_company.kyc_shareholders.splice(i, 1);
    }
  }

  removeKYCShareholderCommittee(i, j) {
    this.seller.kyc_company.kyc_shareholders[i].kyc_shareholders_committee.splice(j, 1);
  }

  changeKYCShareholder(val, key, i) {
    this.seller.kyc_company.kyc_shareholders[i][key] = val;
  }

  changeKYCShareholderCommittee(val, key, i, j) {
    this.seller.kyc_company.kyc_shareholders[i].kyc_shareholders_committee[j][key] = val;
  }

  async uploadBankAccountImage() {
    try {
      let { seller, channel } = this.getData();
      let file = seller.sellers_account.bank_account_image;
      let formData = new FormData();
      formData.append('bookbank', file, file.name);
      formData.append('uid', seller.uid);
      formData.append('channel_uid', channel.uid);

      let url = `${config.npay.apiUrl}/sellers/bookbank/file`;
      let response = await http.post(url, { body: formData });
      if (response.status === 200) {
        let body = await response.json();
        return body.data.url;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async uploadKYCFile(files) {
    try {
      let { seller, channel } = this.getData();
      let formData = new FormData();
      formData.append('uid', seller.uid);
      formData.append('channel_uid', channel.uid);

      for (let prop in files) {
        if (typeof files[prop] === 'object') {
          formData.append(prop, files[prop]);
        }
      }

      let url = `${config.npay.apiUrl}/sellers/kyc/file`;
      let response = await http.post(url, { body: formData });
      if (response.status === 200) {
        let body = await response.json();
        return body.data;
      }
    } catch (err) {
      console.error(err);
    }
  }

  validateSellerData(data) {
    let keys = Object.keys(data);
    let passed = true;
    for (let key of keys) {
      if (key === 'bank_account_no') {
        if (!data[key] || data[key].length < 10) {
          passed = '';
        }
      } else {
        passed = passed && data[key];
        console.log(key, passed);
      }
    }
    return passed;
  }

  validateKYCShareholders(shareholders) {
    let passed = true;
    shareholders.forEach(el => {
      let keys = Object.keys(el);
      for (let key of keys) {
        if (key === 'tax_id' || key === 'name' || key === 'lastname') {
          if (!el.tax_id && !el.name) {
            passed = '';
          }
          if (el.type === 'individual' && (el.name || el.lastname) && !(el.name && el.lastname)) {
            passed = '';
          }
          if (el.tax_id && el.tax_id.length < 13) {
            passed = '';
          }
        } else if (key === 'kyc_shareholders_committee') {
          // not
        } else {
          passed = passed && el[key];
          console.log(key, passed);
        }
      }
    });
    return passed;
  }

  validateKYCCompanyFiles(files) {
    let keys = Object.keys(files);
    let passed = true;
    for (let key of keys) {
      if (key === 'beneficial_owner_authorization') {
        // not required
      } else {
        passed = passed && files[key];
        console.log(key, passed);
      }
    }
    return passed;
  }

  validateKYCCompany(data, status) {
    let keys = Object.keys(data);
    let passed = true;
    for (let key of keys) {
      if (key === 'company_taxid' || key === 'beneficial_owner_personal_id') {
        if (!data[key] || data[key].length < 13) {
          passed = '';
        }
      } else if (key === 'kyc_shareholders') {
        let testShareholders = this.validateKYCShareholders(data[key]);
        if (!testShareholders) {
          passed = '';
        }
      } else if (key === 'upload' && status === 'created') {
        let testUploadFiles = this.validateKYCCompanyFiles(data[key]);
        if (!testUploadFiles) {
          passed = '';
        }
      } else {
        passed = passed && data[key];
      }
    }
    return passed;
  }

  validateKYCPersonal(data) {
    let keys = Object.keys(data);
    let passed = true;
    for (let key of keys) {
      if (key === 'upload') {
        if (!this.validateKYCPersonalFiles(data[key])) {
          return false;
        }
      }
    }
    return passed;
  }

  validateKYCPersonalFiles(files) {
    let keys = Object.keys(files);
    let passed = true;
    console.log('keys', keys);
    for (let key of keys) {
      if (files[key] !== '') {
        // do nothing
      } else {
        passed = false;
      }
    }
    return passed;
  }

  validateTaxId() {
    let { taxId } = this.getData();
    if (!taxId || taxId.length !== 13) {
      return false;
    }
    return true;
  }

  async fetchChannelByPublicUid(uid) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/channels/detail`;
      let response = await http.post(url, { body: { uid } });
      if (response.status === 200) {
        let body = await response.json();
        this.channel = body.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async createChannelSeller() {
    try {
      this.loading = true;
      let { channel, sellerKyc } = this.getData();
      let url = `${config.npay.apiUrl}/sellers/channel`;
      let data = {
        channel_id: channel.id,
        seller_id: sellerKyc.seller_id,
      };
      let response = await http.post(url, { body: data });

      if (response.status === 200) {
        Swal.fire({
          type: 'success',
          title: 'ลงทะเบียนสำเร็จ!',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 200);
        });
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        type: 'error',
        title: 'เกิดข้อผิดพลาด!',
        text: err.message,
        confirmButtonText: 'ตกลง',
      });
    } finally {
      this.loading = false;
    }
  }

  async createSeller() {
    try {
      this.loading = true;
      let { isRegistered, seller, kycPersonalUpload, kycCompanyUpload, sellerKyc, channelSeller, taxId, channel } = this.getData();

      if (isRegistered === null) {
        throw new Error('กรุณากรอกเลขประจำตัวประชาชน/นิติบุคคลและกดค้นหา');
      } else if (!_.isEmpty(channelSeller)) {
        Swal.fire({
          text: sellerKyc.status === 'approved' ? 'ท่านเคยลงทะเบียนไว้แล้ว ได้รับการอนุมัติแล้ว' : 'ท่านเคยลงทะเบียนไว้แล้ว กรุณารอการอนุมัติ',
          type: 'info',
          confirmButtonText: 'ตกลง',
        });
        return;
      } else if (!_.isEmpty(sellerKyc)) {
        this.createChannelSeller();
        return;
      }

      let sellerData = {
        channel_uid: channel.uid,
        // code: seller.sellers_account.dealer_code || null,
        uid: seller.uid,
        name: seller.name,
        // type: seller.type,
        // email: seller.email,
        phone_number: seller.phone_number,
        kyc_type: seller.kyc_type,
        bank_code: seller.sellers_account.bank_code,
        bank_name: seller.sellers_account.bank_name,
        bank_account_no: seller.sellers_account.bank_account_no,
        bank_account_name: seller.sellers_account.bank_account_name,
        bank_account_image: seller.sellers_account.bank_account_image,
      };

      let passed = this.validateSellerData(sellerData);
      if (!passed) throw new Error('กรุณากรอกข้อมูลผู้ขายให้ถูกต้องและครบถ้วน');

      let kycData = {};
      if (seller.kyc_type === 'personal') {
        kycData = {
          name: seller.kyc_personal.name,
          personal_id: taxId,
          upload: kycPersonalUpload,
        };

        let passed = this.validateKYCPersonal(kycData);
        if (!passed) throw new Error('กรุณากรอกข้อมูล KYC ให้ถูกต้องและครบถ้วน');
        console.log('validateKYCPersonal ::', passed);

        let kycUpload = await this.uploadKYCFile(kycPersonalUpload);
        if (_.isEmpty(kycUpload)) throw new Error('ไม่สามารถอัปโหลดไฟล์ KYC ได้ กรุณาลองใหม่อีกครั้ง');
        kycData.upload = kycUpload;
      } else if (seller.kyc_type === 'company') {
        kycData = {
          company_taxid: taxId,
          beneficial_owner_name: seller.kyc_company.beneficial_owner_name,
          beneficial_owner_personal_id: seller.kyc_company.beneficial_owner_personal_id,
          kyc_shareholders: seller.kyc_company.kyc_shareholders,
          upload: kycCompanyUpload,
        };

        let passed = this.validateKYCCompany(kycData, 'created');
        if (!passed) throw new Error('กรุณากรอกข้อมูล KYC ให้ถูกต้องและครบถ้วน');
        console.log('validateKYCCompany ::', passed);

        let kycUpload = await this.uploadKYCFile(kycCompanyUpload);
        if (_.isEmpty(kycUpload)) throw new Error('ไม่สามารถอัปโหลดไฟล์ KYC ได้ กรุณาลองใหม่อีกครั้ง');
        kycData.upload = kycUpload;
      }
      let data = {
        ...sellerData,
        kyc: kycData,
      };
      if (data.bank_account_image.name) {
        let imageUrl = await this.uploadBankAccountImage();
        if (!imageUrl) throw new Error('ไม่สามารถอัปโหลดไฟล์สมุดบัญชีได้ กรุณาลองใหม่อีกครั้ง');
        data.bank_account_image = imageUrl;
      }

      let url = `${config.npay.apiUrl}/sellers`;
      let response = await http.post(url, { body: data });
      if (response.status === 200) {
        Swal.fire({
          type: 'success',
          title: 'ลงทะเบียนสำเร็จ!',
          text: 'โปรดรอดำเนินการอนุมัติ',
          confirmButtonText: 'ตกลง',
        }).then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 200);
        });
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        type: 'error',
        title: 'เกิดข้อผิดพลาด!',
        text: err.message,
        confirmButtonText: 'ตกลง',
      });
    } finally {
      this.loading = false;
    }
  }

  async checkTaxId() {
    if (!this.validateTaxId()) {
      Swal.fire({
        type: 'error',
        text: 'กรุณากรอกเลขประจำตัวประชาชน/นิติบุคคล 13 หลัก',
        confirmButtonText: 'ตกลง',
      });
      return;
    }
    try {
      this.loading = true;
      let { taxId, channel } = this.getData();
      let url = `${config.npay.apiUrl}/channels/${channel.id}/seller/kyc/${taxId}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let data = body.data;
        if (!_.isEmpty(data.channel_seller)) {
          // member of this channel
          let text = `ท่านเคยลงทะเบียนกับร้าน ${channel.name} ไว้แล้ว`;
          Swal.fire({
            text: `${text} ${data.kyc.status === 'approved' ? 'ได้รับการอนุมัติแล้ว' : 'กรุณารอการอนุมัติ'}`,
            type: 'info',
            confirmButtonText: 'ตกลง',
          });
          this.sellerKyc = data.kyc;
          this.channelSeller = data.channel_seller;
          this.changeIsRegistered(true);
        } else if (!_.isEmpty(data.kyc)) {
          // non-member of this channel
          Swal.fire({
            text: `ท่านมีข้อมูลในระบบแล้ว กรุณากดปุ่มลงทะเบียนเพื่อลงทะเบียนกับ ${channel.name}`,
            type: 'info',
            confirmButtonText: 'ตกลง',
          });
          this.sellerKyc = data.kyc;
          this.channelSeller = {};
          this.changeIsRegistered(true);
        } else {
          // guest
          this.sellerKyc = {};
          this.channelSeller = {};
          this.changeIsRegistered(false);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
}
