import React, { Fragment } from 'react';

import logo from '../assets/img/nexterlogo.png';
import MetaTags from 'react-meta-tags';

class Home extends React.Component {
  render() {
    return (
      <Fragment>
        <div style={{ padding: 30 }}>
          <h3 className="text-center">
            <img alt="" height="40" src={logo} /> {'https://www.nexterpay.com'}
          </h3>
        </div>
        <MetaTags>
          <meta name="google-site-verification" content="ooFCdHJ_icBvt5wkGMsAj2DnnT_yWrtXb6VdIUxHs0k" />
        </MetaTags>
      </Fragment>
    );
  }
}

export default Home;
