/* global FormData */
class Http {
  /*   headers() {
      return {
        'content-type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem('token')}`,
      };
    }
   */
  headers(params) {
    let defaultHeaders = {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };
    switch (true) {
      case params.body instanceof FormData:
        return { ...defaultHeaders };
      case typeof params.body === 'object':
        return { ...defaultHeaders, 'Content-Type': 'application/json' };
      default:
        return { ...defaultHeaders };
    }
  }

  parseBody(params) {
    if (params.body) {
      switch (true) {
        case params.body instanceof FormData:
          return params.body;
        case typeof params.body === 'object':
          return JSON.stringify(params.body);
        default:
          return params.body;
      }
    }
  }

  get(url, params = {}) {
    let options = {
      method: 'GET',
      headers: params.headers || this.headers(params),
    };
    return fetch(url, options);
  }

  post(url, params = {}) {
    let options = {
      method: 'POST',
      headers: params.headers || this.headers(params),
      body: this.parseBody(params),
    };
    return fetch(url, options);
  }

  put(url, params = {}) {
    let options = {
      method: 'PUT',
      headers: params.headers || this.headers(params),
      body: this.parseBody(params),
    };
    return fetch(url, options);
  }

  delete(url, params = {}) {
    let options = {
      method: 'DELETE',
      headers: params.headers || this.headers(params),
    };
    return fetch(url, options);
  }
}

export const http = new Http();
