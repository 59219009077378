const config = {
  ccpp: {
    securePayUrl: 'https://t.2c2p.com/SecurePayment/PaymentAuth.aspx',
    my2c2pUrl: 'https://t.2c2p.com/SecurePayment/api/my2c2p.1.6.9.min.js',
  },
  npay: {
    webUrl: 'https://admin.sfinpay.com',
    apiUrl: 'https://api.sfinpay.com',
  },
  kbank: {
    kinlinepaymentUrl: 'https://kpaymentgateway.kasikornbank.com/ui/v2/kinlinepayment.min.js',
  },
  ktc: {
    payment_url: 'https://paygate.ktc.co.th/ktc/eng/merchandize/payment/payForm.jsp',
    merchantId: '',
    currCode_th: '764',
    currCode_us: '840',
    payType: 'N',
    lang: 'T',
    txType: 'Retail',
  },
  bbl: {
    postBackUrl: 'https://nexterpay.com/api/payments/postback/bbl/',
    chargeActionUrl: 'https://ipay.bangkokbank.com/b2c/eng/payment/payerVerify.jsp',
    apiId: '',
  },
  features: {
    mainCredit: true,
    scgWallet: true,
    ktcScg: false,
    multiGateway: false,
    qr: false,
    line: true,
    changFamilyWallet: true,
  },
  auth: {
    pkey: 'RZcUHFlE1kMyHNDgHazIrWkr7vMckcGk',
  },
  time_calibate: {
    UTC: 7,
  },
};

module.exports = config;
