import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

import FilePicker from '../../components/forms/FilePicker';
import Number from '../../components/forms/Number';

class SellerRegisterKYCPersonal extends Component {
  changeKYCPersonalInput(key, event) {
    this.props.sellerRegister.changeKYCPersonalInput(event.target.value, key);
  }

  changeKYCPersonalFile(key, files) {
    if (files && files.length) {
      if (files.length > 1) files.shift();
      this.props.sellerRegister.changeKYCPersonalFile(files[0], key);
    }
  }

  render() {
    const { seller, kycPersonalUpload, taxId } = this.props.sellerRegister.getData();
    const colStyle = { size: 9, offset: 1 };
    const data = _.get(seller, 'kyc_personal', {});
    return (
      <Fragment>
        <Row className="mt-4">
          <Col className="align-self-center">
            <b>ข้อมูลบุคคลธรรมดา</b>
          </Col>
          <Col sm={{ size: 12 }}>
            <hr />
          </Col>
        </Row>
        <Row className="content my-3">
          <Col md={colStyle}>
            <FormGroup row className="align-items-center required">
              <Label sm="5" className="control-label">
                ชื่อ - นามสกุล
                <br />
                <small>(ภาษาไทย)</small>
              </Label>
              <Col sm="6">
                <Input value={data.name || ''} onChange={this.changeKYCPersonalInput.bind(this, 'name')} />
              </Col>
            </FormGroup>
          </Col>
          <Col md={colStyle} className="mt-3">
            <FormGroup row className="align-items-center required">
              <Label sm="5" className="control-label">
                เลขประจำตัวประชาชน
              </Label>
              <Col sm="6">
                {/* <Number value={data.personal_id || ''} onChange={this.changeKYCPersonalInput.bind(this, 'personal_id')} maxLength={13} /> */}
                <Number value={taxId} maxLength={13} readOnly />
              </Col>
            </FormGroup>
          </Col>
          <Col md={colStyle} className="mt-3">
            <FormGroup row className="align-items-center required">
              <Label sm="5" className="control-label" for="fileKYCPersonal">
                แบบรายการแสดงตน KYC
                <br />
                <small>(สำหรับบุคคลธรรมดา)</small>
              </Label>
              <Col sm="6">
                {data.file_kyc_personal ? (
                  <p className="my-2">
                    <a target="_blank" href={data.file_kyc_personal}>
                      แบบรายการแสดงตน KYC
                    </a>
                  </p>
                ) : (
                  ''
                )}
                <FilePicker
                  src={kycPersonalUpload.kyc_personal}
                  onDrop={this.changeKYCPersonalFile.bind(this, 'kyc_personal')}
                  inputProps={{ multiple: false, accept: 'image/png, image/jpeg, .pdf' }}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={colStyle} className="mt-3">
            <FormGroup row className="align-items-center required">
              <Label sm="5" className="control-label" for="fileIdCard">
                สำเนาบัตรประชาชน พร้อมลงลายมือชื่อ
              </Label>
              <Col sm="6">
                {data.file_idcard ? (
                  <p className="my-2">
                    <a target="_blank" href={data.file_idcard}>
                      สำเนาบัตรประชาชน
                    </a>
                  </p>
                ) : (
                  ''
                )}
                <FilePicker src={kycPersonalUpload.idcard} onDrop={this.changeKYCPersonalFile.bind(this, 'idcard')} inputProps={{ multiple: false, accept: 'image/png, image/jpeg, .pdf' }} />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default inject('sellerRegister')(observer(SellerRegisterKYCPersonal));
